<template>
  <div class="wws">
    <img src="../assets/pa/tdjs.png" alt="" class="bg" />
    <div class="bgss">
      <div class="ybtc">
        <img src="../assets/pa/jsbt.png" alt="" />
      </div>

      <!-- 关于团队-->
      <div class="gytd">
        <img src="../assets/jbjs/gytdtit.png" alt="" class="gytdt" />
        <div class="gyddk">
          <div class="gytdtit">{{ ymnr.tdna }}</div>
          <div class="gyjss">
            {{ ymnr.tdjs }}
          </div>
        </div>
      </div>
      <!-- 轮播 -->
      <div class="gdzx">
        <img src="../assets/pa/tdzxxm.png" alt="" class="tdzx" />
        <div class="lbrc">
          <div class="gwhgk">
            <div class="xhgd" id="gdk">
              <ul id="ul1" class="ul1">
                <li
                  class="oli"
                  v-for="(val, i) in moll"
                  :key="i"
                  :id="`ds${i}`"
                >
                  <img
                    :src="val.imgd"
                    alt=""
                    class="t1"
                    @click="fdtp(val.imgd)"
                  />
                  <div class="t2">
                    <img
                      :src="val.img1"
                      alt=""
                      class="s1"
                      @click="fdtp(val.img1)"
                    />
                    <img
                      :src="val.img2"
                      alt=""
                      class="s2"
                      @click="fdtp(val.img2)"
                    />
                  </div>
                  <div class="t2">
                    <img
                      :src="val.img3"
                      alt=""
                      class="s1"
                      @click="fdtp(val.img3)"
                    />
                    <img
                      :src="val.img4"
                      alt=""
                      class="s2"
                      @click="fdtp(val.img4)"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- <img src="../assets/pa/tbtb.png" alt="" class="tbtb"> -->
      </div>
      <!-- z底部装饰 -->
      <img src="../assets/pa/yydb.png" alt="" class="yydb" />
      <!-- <img src="../assets/pa/yydb.png" alt="" class="yydb"> -->
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      lis: 0, //所有图片的宽度
      moll: [],
      ymnr: {}, //页面的整个内容
      mossz: [], //轮播图中的图片
      tpyl: [], //图片预览
      wang: [
        { payh: "王玲" },
        { pahlw: "平安健康互联网" },
        { pakj: "平安科技" },
        { pazj: "平安证券" },
        { ljs: "陆金所" },
        { pagj: "金管家" },
        { zn: "知鸟" },
        { pacx: "平安好车主" },
        { yzt: "金融壹帐通" },    
        { payhs: "平安银行" },
        { pakjj: "平安产险科技体现团队" },

      ],
      tdjszxx: {
        //-------------------------------------------------------------------------------------------------
        //金融壹账通-------------------------------------------------------------------------------------------------
        yzt: {
          tdna: "金融壹账通",
          tdjs: "集团首个用户体验团队，前身是成立于2011年的平安金科客户体验部。专注于金融壹账通零售产品的标准化设计及规范，针对客户需求进行定制化输出。在智能配置平台搭建及B端的设计创新有着丰富的经验。",
          tptd: [
            {
              imgd: require("../assets/jryzt/1d.png"),
              img1: require("../assets/jryzt/1s.png"),
              img2: require("../assets/jryzt/2s.png"),
              img3: require("../assets/jryzt/3s.png"),
              img4: require("../assets/jryzt/4s.png"),
            },
          ],
          tul: [
            require("../assets/jryzt/1d.png"),
            require("../assets/jryzt/1s.png"),
            require("../assets/jryzt/2s.png"),
            require("../assets/jryzt/3s.png"),
            require("../assets/jryzt/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //陆金所-------------------------------------------------------------------------------------------------
        ljs: {
          tdna: "陆金所",
          tdjs: "陆金所是纽交所上市企业，为超4600万用户提供8600种理财产品及相关服务。设计团队规模约20人，由交互设计、UI视觉设计、营销视觉设计组成，在互联网投资理财设计领域经验丰富，专业创新是我们坚持的团队精神。",
          tptd: [
            {
              imgd: require("../assets/ljs/1d.png"),
              img1: require("../assets/ljs/1s.png"),
              img2: require("../assets/ljs/2s.png"),
              img3: require("../assets/ljs/3s.png"),
              img4: require("../assets/ljs/4s.png"),
            },
          ],
          tul: [
            require("../assets/ljs/1d.png"),
            require("../assets/ljs/1s.png"),
            require("../assets/ljs/2s.png"),
            require("../assets/ljs/3s.png"),
            require("../assets/ljs/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------

        //平安产险科技-------------------------------------------------------------------------------------------------
        pakjj: {
          tdna: "平安产险科技体验设计团队",
          tdjs: "平安产险科技体验设计团队（PATD），负责平安产险科技生态体系下的用户及商业产品的体验设计。团队秉承“简单包容可进化”的设计理念，探索科技与商业的有机融合，创造更加美好的保险科技产品与服务，践行并赋能公司“科技+金融”的经营理念。",
          tptd: [
            {
              imgd: require("../assets/pacx/1d.png"),
              img1: require("../assets/pacx/1s.png"),
              img2: require("../assets/pacx/2s.png"),
              img3: require("../assets/pacx/3s.png"),
              img4: require("../assets/pacx/4s.png"),
            },
          ],
          tul: [
            require("../assets/pacx/1d.png"),
            require("../assets/pacx/1s.png"),
            require("../assets/pacx/2s.png"),
            require("../assets/pacx/3s.png"),
            require("../assets/pacx/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //平安好车主-------------------------------------------------------------------------------------------------
        pacx: {
          tdna: "平安好车主",
          tdjs: "平安产险设计团队——产险设计事务所作为一支围绕车服务、车保险、车生活BC端生态体验团队，致力于车后服务市场的探索。让设计师前置化，通过用户体验赋能产品。团队现有成员30+，汇集了腾讯、京东等互联网公司的优秀人才，极致体验是我们的追求。",
          tptd: [
            {
              imgd: require("../assets/pac/1d.png"),
              img1: require("../assets/pac/1s.png"),
              img2: require("../assets/pac/2s.png"),
              img3: require("../assets/pac/3s.png"),
              img4: require("../assets/pac/4s.png"),
            },
          ],
          tul: [
            require("../assets/pac/1d.png"),
            require("../assets/pac/1s.png"),
            require("../assets/pac/2s.png"),
            require("../assets/pac/3s.png"),
            require("../assets/pac/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //平安健康互联网-------------------------------------------------------------------------------------------------
        pahlw: {
          tdna: "平安健康互联网",
          tdjs: "平安健康作为港交所上市的互联网医疗第一股，累计注册用户数达4亿人。平台借助自有医疗团队7*24小时的全天候服务以及外部名医大咖的权威指导，用户足不出户即可体验到问诊、开方、用药指导、报告解读、随访关怀等各项服务。UED团队是由交互、UI、品牌设计师组成的41人的团队，为公司各项业务提供全方位的设计赋能。",
          tptd: [
            {
              imgd: require("../assets/pajk/1d.png"),
              img1: require("../assets/pajk/1s.png"),
              img2: require("../assets/pajk/2s.png"),
              img3: require("../assets/pajk/3s.png"),
              img4: require("../assets/pajk/4s.png"),
            },
          ],
          tul: [
            require("../assets/pajk/1d.png"),
            require("../assets/pajk/1s.png"),
            require("../assets/pajk/2s.png"),
            require("../assets/pajk/3s.png"),
            require("../assets/pajk/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //平安金管家-------------------------------------------------------------------------------------------------
        pagj: {
          tdna: "平安金管家",
          tdjs: "平安金管家是平安人寿全力打造推出的官方APP，也是2.4亿人都在用的财富生活管家。平安金管家用户体验设计中心简称PIUX，团队规模约30人，由交互设计、UI设计、视觉设计、动效设计组成，是一群鲜活灵动并富有灵魂的设计师。",
          tptd: [
            {
              imgd: require("../assets/pagj/1d.png"),
              img1: require("../assets/pagj/1s.png"),
              img2: require("../assets/pagj/2s.png"),
              img3: require("../assets/pagj/3s.png"),
              img4: require("../assets/pagj/4s.png"),
            },
          ],
          tul: [
            require("../assets/pagj/1d.png"),
            require("../assets/pagj/1s.png"),
            require("../assets/pagj/2s.png"),
            require("../assets/pagj/3s.png"),
            require("../assets/pagj/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //平安科技-------------------------------------------------------------------------------------------------
        pakj: {
          tdna: "平安科技",
          tdjs: "XDC依托于平安集团强大的综合金融实力以及平安科技顶尖的技术背景，提供集团内外多领域体验研究及设计服务，如，企业经营数字化、金融云、信息安全等领域，我们致力于让科技+金融之路变得更加愉悦、美好。",
          tptd: [
            {
              imgd: require("../assets/pakj/1d.png"),
              img1: require("../assets/pakj/1s.png"),
              img2: require("../assets/pakj/2s.png"),
              img3: require("../assets/pakj/3s.png"),
              img4: require("../assets/pakj/4s.png"),
            },
          ],
          tul: [
            require("../assets/pakj/1d.png"),
            require("../assets/pakj/1s.png"),
            require("../assets/pakj/2s.png"),
            require("../assets/pakj/3s.png"),
            require("../assets/pakj/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //平安证券-------------------------------------------------------------------------------------------------
        pazj: {
          tdna: "平安证券",
          tdjs: "平安证券经纪用户体验团队主要负责平安证券股票、理财、期货、两融等线上线下各类业务的产品及营销的设计体验服务，专注于产品设计、交互设计、体验设计领域的不断实践和创新。旨在打造平安综合金融服务战略下智能化的证券服务平台。",
          tptd: [
            {
              imgd: require("../assets/pazj/1d.png"),
              img1: require("../assets/pazj/1s.png"),
              img2: require("../assets/pazj/2s.png"),
              img3: require("../assets/pazj/3s.png"),
              img4: require("../assets/pazj/4s.png"),
            },
          ],
          tul: [
            require("../assets/pazj/1d.png"),
            require("../assets/pazj/1s.png"),
            require("../assets/pazj/2s.png"),
            require("../assets/pazj/3s.png"),
            require("../assets/pazj/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //银行-王玲WOW-------------------------------------------------------------------------------------------------
        payh: {
          tdna: "WOW",
          tdjs: "WOW Design隶属于IT对公派驻科技团队，专注于平安银行B端场景的产品创新与体验设计服务。目前有设计师60+，工作职能涵盖交互设计、UI设计、体验评测、用户研究、体验布道等模块。利用体验设计思维与方法，深耕B端金融场景，助力业务上量。",
          tptd: [
            {
              imgd: require("../assets/yh/1d.png"),
              img1: require("../assets/yh/1s.png"),
              img2: require("../assets/yh/2s.png"),
              img3: require("../assets/yh/3s.png"),
              img4: require("../assets/yh/4s.png"),
            },
            {
              imgd: require("../assets/yh/2d.png"),
              img1: require("../assets/yh/1s2.png"),
              img2: require("../assets/yh/2s2.png"),
              img3: require("../assets/yh/3s2.png"),
              img4: require("../assets/yh/4s2.png"),
            },
          ],
          tul: [
            require("../assets/yh/1d.png"),
            require("../assets/yh/1s.png"),
            require("../assets/yh/2s.png"),
            require("../assets/yh/3s.png"),
            require("../assets/yh/4s.png"),
            require("../assets/yh/2d.png"),
            require("../assets/yh/1s2.png"),
            require("../assets/yh/2s2.png"),
            require("../assets/yh/3s2.png"),
            require("../assets/yh/4s2.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //-------------------------------------------------------------------------------------------------
        //知鸟-------------------------------------------------------------------------------------------------
        zn: {
          tdna: "知鸟",
          tdjs: "知鸟设计体验中心，是平安国际智慧城市下一支具有完善设计生态的UX体验团队，具备规模化解决企业级产品设计交付的能力。主要负责平安知鸟智慧教育SaaS产品的体验研究和设计服务，致力于帮助企业快速构建「美好」、「简单」的数字体验。",
          tptd: [
            {
              imgd: require("../assets/zn/1d.png"),
              img1: require("../assets/zn/1s.png"),
              img2: require("../assets/zn/2s.png"),
              img3: require("../assets/zn/3s.png"),
              img4: require("../assets/zn/4s.png"),
            },
            {
              imgd: require("../assets/zn/2d.png"),
              img1: require("../assets/zn/1s2.png"),
              img2: require("../assets/zn/2s2.png"),
              img3: require("../assets/zn/3s.png"),
              img4: require("../assets/zn/4s.png"),
            },
          ],
          tul: [
            require("../assets/zn/1d.png"),
            require("../assets/zn/1s.png"),
            require("../assets/zn/2s.png"),
            require("../assets/zn/3s.png"),
            require("../assets/zn/4s.png"),
            require("../assets/zn/2d.png"),
            require("../assets/zn/1s2.png"),
            require("../assets/zn/2s2.png"),
            require("../assets/zn/3s.png"),
            require("../assets/zn/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //平安银行-------------------------------------------------------------------------------------------------
        payhs: {
          tdna: "平安银行",
          tdjs: "PBDC由用户研究、产品设计、创意设计、IP设计、前端开发等150+成员组成。  团队致力于打造中国最卓越、全球领先的智能化零售银行，利用体验技术赋能产品、服务客户创造价值、不断探索更美好的金融体验。",
          tptd: [
            {
              imgd: require("../assets/pxxh/1d.png"),
              img1: require("../assets/pxxh/1s.png"),
              img2: require("../assets/pxxh/2s.png"),
              img3: require("../assets/pxxh/3s.png"),
              img4: require("../assets/pxxh/4s.png"),
            },
            {
              imgd: require("../assets/pxxh/2d.png"),
              img1: require("../assets/pxxh/1s2.png"),
              img2: require("../assets/pxxh/2s2.png"),
              img3: require("../assets/pxxh/3s2.png"),
              img4: require("../assets/pxxh/4s2.png"),
            },
            {
              imgd: require("../assets/pxxh/3d.png"),
              img1: require("../assets/pxxh/1s3.png"),
              img2: require("../assets/pxxh/2s3.png"),
              img3: require("../assets/pxxh/3s3.png"),
              img4: require("../assets/pxxh/4s3.png"),
            },
            {
              imgd: require("../assets/pxxh/4d.png"),
              img1: require("../assets/pxxh/1s4.png"),
              img2: require("../assets/pxxh/2s4.png"),
              img3: require("../assets/pxxh/3s4.png"),
              img4: require("../assets/pxxh/4s4.png"),
            },
            {
              imgd: require("../assets/pxxh/5d.png"),
              img1: require("../assets/pxxh/1s5.png"),
              img2: require("../assets/pxxh/2s5.png"),
              img3: require("../assets/pxxh/3s5.png"),
              img4: require("../assets/pxxh/4s5.png"),
            },
            {
              imgd: require("../assets/pxxh/6d.png"),
              img1: require("../assets/pxxh/1s6.png"),
              img2: require("../assets/pxxh/2s6.png"),
              img3: require("../assets/pxxh/3s6.png"),
              img4: require("../assets/pxxh/4s6.png"),
            },
            {
              imgd: require("../assets/pxxh/7d.png"),
              img1: require("../assets/pxxh/1s7.png"),
              img2: require("../assets/pxxh/2s7.png"),
              img3: require("../assets/pxxh/3s7.png"),
              img4: require("../assets/pxxh/4s7.png"),
            },
            {
              imgd: require("../assets/pxxh/8d.png"),
              img1: require("../assets/pxxh/1s8.png"),
              img2: require("../assets/pxxh/2s8.png"),
              img3: require("../assets/pxxh/3s.png"),
              img4: require("../assets/pxxh/4s.png"),
            },
          ],
          tul: [
            require("../assets/pxxh/1d.png"),
            require("../assets/pxxh/1s.png"),
            require("../assets/pxxh/2s.png"),
            require("../assets/pxxh/3s.png"),
            require("../assets/pxxh/4s.png"),
            require("../assets/pxxh/2d.png"),
            require("../assets/pxxh/1s2.png"),
            require("../assets/pxxh/2s2.png"),
            require("../assets/pxxh/3s2.png"),
            require("../assets/pxxh/4s2.png"),
            require("../assets/pxxh/3d.png"),
            require("../assets/pxxh/1s3.png"),
            require("../assets/pxxh/2s3.png"),
            require("../assets/pxxh/3s3.png"),
            require("../assets/pxxh/4s3.png"),
            require("../assets/pxxh/4d.png"),
            require("../assets/pxxh/1s4.png"),
            require("../assets/pxxh/2s4.png"),
            require("../assets/pxxh/3s4.png"),
            require("../assets/pxxh/4s4.png"),
            require("../assets/pxxh/5d.png"),
            require("../assets/pxxh/1s5.png"),
            require("../assets/pxxh/2s5.png"),
            require("../assets/pxxh/3s5.png"),
            require("../assets/pxxh/4s5.png"),
            require("../assets/pxxh/6d.png"),
            require("../assets/pxxh/1s6.png"),
            require("../assets/pxxh/2s6.png"),
            require("../assets/pxxh/3s6.png"),
            require("../assets/pxxh/4s6.png"),
            require("../assets/pxxh/7d.png"),
            require("../assets/pxxh/1s7.png"),
            require("../assets/pxxh/2s7.png"),
            require("../assets/pxxh/3s7.png"),
            require("../assets/pxxh/4s7.png"),
            require("../assets/pxxh/8d.png"),
            require("../assets/pxxh/1s8.png"),
            require("../assets/pxxh/2s8.png"),
            require("../assets/pxxh/3s.png"),
            require("../assets/pxxh/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
      }, //所有的数据信息
    };
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
      //     wang= [
      //   0{ payh: "王玲" },
      //   1{ pahlw: "平安健康互联网" },
      //   2{ pakj: "平安科技" },
      //   3{ pazj: "平安证券" },
      //   4{ ljs: "陆金所" },
      //   5{ pagj: "金管家" },
      //   6{ zn: "知鸟" },
      //   7{ pacx: "平安好车主" },
      //   8{ yzt: "金融壹帐通" },    
      //   9{ payhs: "平安银行" },
      //   10{ pakjj: "平安产险科技体现团队" },
      // ],
      if(this.$route.params.na==0){
      this.ymnr = this.tdjszxx.payh;
      window._czc.push(["_trackEvent", "点击", "团队", "WOW", "1", "1"]);
    }else if(this.$route.params.na==1){
      this.ymnr = this.tdjszxx.pahlw;
      window._czc.push(["_trackEvent", "点击", "团队", "平安健康互联网", "1", "1"]);
    }else if(this.$route.params.na==2){
      this.ymnr = this.tdjszxx.pakj;
      window._czc.push(["_trackEvent", "点击", "团队", "平安科技", "1", "1"]);
    }else if(this.$route.params.na==3){
      this.ymnr = this.tdjszxx.pazj;
      window._czc.push(["_trackEvent", "点击", "团队", "平安证券", "1", "1"]);
    }else if(this.$route.params.na==4){
      this.ymnr = this.tdjszxx.ljs;
      window._czc.push(["_trackEvent", "点击", "团队", "陆金所", "1", "1"]);
    }else if(this.$route.params.na==5){
      this.ymnr = this.tdjszxx.pagj;
      window._czc.push(["_trackEvent", "点击", "团队", "金管家", "1", "1"]);

    }else if(this.$route.params.na==6){
      this.ymnr = this.tdjszxx.zn;
      window._czc.push(["_trackEvent", "点击", "团队", "知鸟", "1", "1"]);

    }else if(this.$route.params.na==7){
      this.ymnr = this.tdjszxx.pacx;
      window._czc.push(["_trackEvent", "点击", "团队", "平安好车主", "1", "1"]);

    }else if(this.$route.params.na==8){
      this.ymnr = this.tdjszxx.yzt;
      window._czc.push(["_trackEvent", "点击", "团队", "金融壹帐通", "1", "1"]);

    }else if(this.$route.params.na==9){
      this.ymnr = this.tdjszxx.payhs;
      window._czc.push(["_trackEvent", "点击", "团队", "平安银行", "1", "1"]);

    }else if(this.$route.params.na==10){
      this.ymnr = this.tdjszxx.pakjj;
      window._czc.push(["_trackEvent", "点击", "团队", "平安产险科技体现团队", "1", "1"]);

    }
    // console.log(this.$route.params.na);
    // this.ymnr = this.tdjszxx.zn;
    this.mossz = this.ymnr.tptd; //轮播图片
    this.tpyl = this.ymnr.tul; //轮播图片预览
    console.log(this.mossz);
    this.lis=0,
    clearInterval(this.timer);
    this.pbl();
  },
  //离开当前页面后执行
destroyed: function () {
    clearInterval(this.timer);

},
  destroyed() {
    clearInterval(this.timer);
    // window.removeEventListener('beforeunload', e => this.test(e))
  },
  methods: {
    // 往期回顾的轮播----------------------------------------------------------------------------------------------------------------------------------------------
    //把拿到的数据分开放到两个数组中
    pbl() {
      this.moll = this.mossz.concat(this.mossz);
      console.log(this.moll);

      setTimeout(() => {
        this.jsul();
      }, 1000);
    },
    // 111往期回顾模块的点击图片放大预览
    fdtp(i) {
      console.log(i);
      var ass = 0;
      for (var a = 0; a < this.tpyl.length; a++) {
        if (this.tpyl[a] == i) {
          ass = a;
        }
      }
      console.log(ass);
      clearInterval(this.timer); //鼠标点击清除定时器
      var this_ = this;
      ImagePreview({
        images: this.tpyl,
        closeable: true,
        // showIndex:false,
        startPosition: ass,
        onClose() {
          console.log(147258);
          this_.timer = setInterval(() => {
            this_.move();
          }, 30);
        },
      });
    },
    //111往期回顾模块获取图片的长度
    jsul() {
      var oli = document.getElementsByClassName("oli");
      for (var i = 0; i < oli.length; i++) {
        this.lis += oli[i].offsetWidth;
        if (i == oli.length - 1) {
          this.tpgd();
          console.log(this.lis);
        }
      }
    },
    //111往期回顾模块把图片的长度赋值给父元素图片动起来
    tpgd() {
      console.log("循环完了", this.lis);
      var oul = document.getElementById("ul1");
      oul.style.width = this.lis + "px";
      this.timer = setInterval(() => {
        this.move();
      }, 30);
    },
    //111往期回顾模块让图片动起来
    move() {
      var oul = document.getElementById("ul1");
      if (oul.offsetLeft < -(oul.offsetWidth / 2)) {
        //向左滚动，当靠左的图4移出边框时
        oul.style.left = 0;
      }
      // if (oul.offsetLeft > 0) { //向右滚动，当靠右的图1移出边框时
      //     oul.style.left = -(oul.offsetWidth / 2) + 'px';
      // }
      oul.style.left = oul.offsetLeft + -1 + "px"; //向左滚动的时候为-2，向右滚动为正2
    },
    // ----------------------------------------------------------------------------------------------------------
  },
};
</script>

<style scoped>
/* ------------------------------------------------- */
.ul1 li .t2 .s1 {
  height: 1.45rem;
  left: 0.05rem;
  position: absolute;
  top: 0rem;
  width: 2.68rem;
  height: 1.41rem;
}
.ul1 li .t2 .s2 {
  height: 1.45rem;
  bottom: 0;
  left: 0.05rem;
  position: absolute;
  width: 2.68rem;
  height: 1.41rem;
}
.ul1 li .t2 {
  /* background-color: rgb(106, 190, 132); */
  position: relative;
  height: 2.9rem;
  width: 2.75rem;
  display: inline-block;
}
.ul1 li .t1 {
  width: 3.7rem;
  height: 2.9rem;
  display: inline-block;
}
.uli li .t2 {
  display: inline-block;
}
.ul1 li {
  /* display: flex; */

  text-align: left;
  display: inline-block;
  /* position: relative; */
  white-space: nowrap;
  height: 2.9rem;
  width: 9.28rem;
  /* background-color: rgb(105, 87, 87); */
}
.ul1 {
  width: 100%;
  height: 100%;
  /* overflow-x: auto; */
  /* overflow-X: hidden; */
  position: absolute;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.xhgd {
  overflow-x: auto;
  overflow-Y: hidden;
  /* overflow: hidden; */
  width: 6.9rem;
  height: 2.9rem;
  /* background-color: rgb(65, 66, 66); */
  position: absolute;
  left: 50%;
  top: 0rem;
  transform: translate(-50%);
}
.xhgds {
  /* overflow-x: auto; */
  /* overflow-Y: hidden; */
  overflow: hidden;
  width: 6.9rem;
  height: 1.47rem;
  /* background-color: aqua; */
  position: absolute;
  left: 50%;
  top: 1.47rem;
  transform: translate(-50%);
}
.lbrc {
  width: 7rem;
  top: 1rem;
  position: absolute;
}
/* ============================================================= */
.yydb {
  position: relative;
  bottom: -1rem;
  /* z-index: 0; */
  width: 100%;
  /* left: 0; */
}
.gytd .gytdt {
  position: relative;
  /* width: 6.9rem; */
  height: 0.6rem;
  left: 0;
}
.gytd {
  text-align: left;
  /* top: 1rem; */
  width: 6.9rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  /* height: 4.2rem; */
  /* margin-top: 0.72rem; */
  /* background-color: salmon; */
}
.gyjss {
  width: 6.27rem;
  position: relative;
  font-size: 0.2rem;
  line-height: 0.38rem;
  margin-top: 0.1rem;
  color: #b8bdd7;
  left: 50%;
  transform: translate(-50%);
  text-align:justify;
}
.gytdtit {
  width: 6.27rem;
  position: relative;
  font-size: 0.25rem;
  font-weight: bold;
  color: rgb(255, 255, 255);
  left: 50%;
  transform: translate(-50%);
}
.gyddk {
  width: 6.9rem;
  /* height: 5rem; */
  border-radius: 0.1rem;
  padding-top: 0.1rem;
  padding-bottom: 6px;
  background-color: rgba(232, 233, 236, 0.1);
  border: 1px solid rgba(232, 233, 236, 0.1);
}
.tbtb {
  width: 3.3rem;
  height: 0.6rem;
  top: 4.2rem;
  right: 0rem;
  position: absolute;
}
.zxxmp {
  width: 6.9rem;
  height: 2.95rem;
  position: absolute;
  left: 0rem;
  top: 0.88rem;
  /* transform: translate(-50%); */
}
.tdzx {
  width: 2.42rem;
  height: 0.64rem;
  position: absolute;
  left: 0rem;
}
.gdzx {
  width: 6.9rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  height: 4.8rem;
  margin-top: 0.72rem;
  /* background-color: salmon; */
}
/* .gytd img{
    position: absolute;
    width: 6.9rem;
    height: 3.51rem;
    left: 0;
} */

/*  */

.ybtc img {
  width: 2.44rem;
  height: 3.79rem;
  position: absolute;
  left: 0rem;
}
.ybtc {
  margin-top: 1rem;
  width: 6.4rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  height: 4.63rem;
  /* background-color: salmon; */
}
.bgss {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
.bg {
  /* background: url(../assets/pa/yy.png) no-repeat; */
  width: 100vw;
  position: relative;
  top: 0rem;
  left: 0;
  display: block;
  font-size: 0%;
  /* min-height: 13rem; */
  /* background-size: cover; */
  /* background-position: center -0.7rem; */
  /* overflow: hidden; */
}
.wws{
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
</style>