<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>
<script>
export default {
  mounted(){
this.suo()
this.share()
  },
  methods:{
// 微信分享
        share() {
      // 微信jsdk
      this.$axios
        .post('https://h5api.xiaoxxx.cn/h5/huaFaPeople/getJsSDKConfig', {
          url: location.href.split("#")[0]
          // url:"https://h5case4.xiaoxxx.cn"
        })
        .then((res) => {
          console.log(res);
          console.log(location.href.split("#")[1]);
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名，见附录1
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData",
            ],
          });

          window.wx.ready(function () {
            // 转发
            window.wx.updateAppMessageShareData({
              title: "平安集团OPA体验设计大会", // 分享标题
              desc: "2021年12⽉19-20⽇，设计⼤咖、权威体验专家，⻬聚平安！", // 分享描述
              link: "https://opa.xiaoxxx.cn", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl:
                "https://res.xiaoxxx.com/test/m.xiaoxxx.com/20211214d3a1a25a424a5a3a140712.png", // 分享图标
              success: function () {},
            });
            // 分享朋友圈
            window.wx.updateTimelineShareData({
              title: "2021年12⽉19-20⽇，设计⼤咖、权威体验专家，⻬聚平安！", // 分享标题
              link: "https://opa.xiaoxxx.cn", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl:
                "https://res.xiaoxxx.com/test/m.xiaoxxx.com/20211214d3a1a25a424a5a3a140712.png", // 分享图标
              success: function () {},
            });
          });
        });

    },


suo() {
      var deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth > 750) {
        deviceWidth = 7.5 * 50;
      }
      document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";
      var lastTouchEnd = 0;
      document.documentElement.addEventListener(
        "touchend",
        function (event) {
          var now = Date.now();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
          }
          lastTouchEnd = now;
        },
        false
      );
      if (document.getElementsByTagName("meta")["viewport"]) {
        document.getElementsByTagName("meta")["viewport"].content =
          "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
      } else {
        var meta = document.createElement("meta");
        meta.content =
          "width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0, user-scalable=0";
        meta.name = "viewport";
        document.getElementsByTagName("head")[0].appendChild(meta);
      }
    },
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
