<template>
  <div class="wws">
    <img src="../assets/pa/ghsbgs.png" alt="" class="bg" />
    <div class="bgsd"></div>
    <div class="bgss">
      <!-- 视频模块 -->
      <div class="ybtc">
        <div class="spbf">
          <video
            :src="ymnr.ship"
            width="350"
            height="200"
            controls
            preload
            id="spbf"
            class="syj"
          ></video>
        </div>
        <div class="smcs" v-if="spbfs == 1">
          <img :src="ymnr.gyst" alt="" class="bffm" />
          <img
            src="../assets/pa/bfkb.png"
            alt=""
            class="bfkb"
            @click="ksbfsp"
          />
        </div>
        <!-- <img src="../assets/pa/jsbt.png" alt=""> -->
      </div>
      <!-- 关于供应商模块 -->
      <div class="gytd">
        <img src="../assets/pa/ghsy.png" alt="" class="gytdtit" />
        <img :src="ymnr.ztbg" alt="" class="gytdtp" />
      </div>
      <!--  轮播模块-->
      <div class="gdzx">
        <img src="../assets/pa/tdzxxm.png" alt="" class="tdzx" />
        <!-- ``````````````````````````````````````````````````````` -->
        <div class="lbrc">
          <div class="gwhgk">
            <div class="xhgd" id="gdk">
              <ul id="ul1" class="ul1">
                <li
                  class="oli"
                  v-for="(val, i) in moll"
                  :key="i"
                  :id="`ds${i}`"
                >
                  <img
                    :src="val.imgd"
                    alt=""
                    class="t1"
                    @click="fdtp(val.imgd)"
                  />
                  <div class="t2">
                    <img
                      :src="val.img1"
                      alt=""
                      class="s1"
                      @click="fdtp(val.img1)"
                    />
                    <img
                      :src="val.img2"
                      alt=""
                      class="s2"
                      @click="fdtp(val.img2)"
                    />
                  </div>
                  <div class="t2">
                    <img
                      :src="val.img3"
                      alt=""
                      class="s1"
                      @click="fdtp(val.img3)"
                    />
                    <img
                      :src="val.img4"
                      alt=""
                      class="s2"
                      @click="fdtp(val.img4)"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- ``````````````````````````````````````````````````````` -->
        <!-- <img src="../assets/pa/tbtb.png" alt="" class="tbtb"> -->
      </div>
      <!-- 底部信息模块 -->
      <div class="lxfsk">
        <img :src="ymnr.lx" alt="" class="lxfs" />
      </div>
      <!-- z底部装饰 -->
      <img src="../assets/pa/yydb.png" alt="" class="yydb" />
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";

export default {
  data() {
    return {
      spbfs: 1, //控制视频播放的时候隐藏封面
      lis: 0, //所有图片的宽度
      moll: [],
      ymnr: {}, //页面的整个内容
      mossz: [], //轮播图中的图片
      tpyl: [], //图片预览
      sjz: {
        // --------小仙---------------------------------------------------------------
        xiaoxian: {
          gyst:require("../assets/fm/xx.jpg"),
          ship: require("../assets/xx/1.mp4"),
          ztbg: require("../assets/xx/ztbg.png"),
          lx: require("../assets/xx/lx.png"),
          tu: [
            {
              imgd: require("../assets/xx/1d.png"),
              img1: require("../assets/xx/1s.png"),
              img2: require("../assets/xx/2s.png"),
              img3: require("../assets/xx/3s.png"),
              img4: require("../assets/xx/4s.png"),
            },
            {
              imgd: require("../assets/xx/2d.png"),
              img1: require("../assets/xx/1s2.png"),
              img2: require("../assets/xx/2s2.png"),
              img3: require("../assets/xx/3s.png"),
              img4: require("../assets/xx/4s.png"),
            },
          ],
          tul: [
            require("../assets/xx/1d.png"),
            require("../assets/xx/1s.png"),
            require("../assets/xx/2s.png"),
            require("../assets/xx/3s.png"),
            require("../assets/xx/4s.png"),
            require("../assets/xx/2d.png"),
            require("../assets/xx/1s2.png"),
            require("../assets/xx/2s2.png"),
            require("../assets/xx/3s.png"),
            require("../assets/xx/4s.png"),
          ],
        },
        // --------视觉---------------------------------------------------------------
        shijue: {
          gyst:require("../assets/fm/sj.png"),
          ship: require("../assets/sj/1.mp4"),
          ztbg: require("../assets/sj/ztbg.png"),
          lx: require("../assets/sj/lx.png"),
          tu: [
            {
              imgd: require("../assets/sj/1d.png"),
              img1: require("../assets/sj/1s.png"),
              img2: require("../assets/sj/2s.png"),
              img3: require("../assets/sj/3s.png"),
              img4: require("../assets/sj/4s.png"),
            },
            {
              imgd: require("../assets/sj/2d.png"),
              img1: require("../assets/sj/1s2.png"),
              img2: require("../assets/sj/2s2.png"),
              img3: require("../assets/sj/3s2.png"),
              img4: require("../assets/sj/4s.png"),
            },
          ],
          tul: [
            require("../assets/sj/1d.png"),
            require("../assets/sj/1s.png"),
            require("../assets/sj/2s.png"),
            require("../assets/sj/3s.png"),
            require("../assets/sj/4s.png"),
            require("../assets/sj/2d.png"),
            require("../assets/sj/1s2.png"),
            require("../assets/sj/2s2.png"),
            require("../assets/sj/3s2.png"),
            require("../assets/sj/4s.png"),
          ],
        },
        // --------汉仪---------------------------------------------------------------
        hanyi: {
          gyst:require("../assets/fm/hy.png"),
          ship: require("../assets/hy/1.mp4"),
          ztbg: require("../assets/hy/ztbg.png"),
          lx: require("../assets/hy/lx.png"),
          tu: [
            {
              imgd: require("../assets/hy/1d.png"),
              img1: require("../assets/hy/1s.png"),
              img2: require("../assets/hy/2s.png"),
              img3: require("../assets/hy/3s.png"),
              img4: require("../assets/hy/4s.png"),
            },
            {
              imgd: require("../assets/hy/2d.png"),
              img1: require("../assets/hy/1s2.png"),
              img2: require("../assets/hy/2s2.png"),
              img3: require("../assets/hy/3s2.png"),
              img4: require("../assets/hy/4s2.png"),
            },
          ],
          tul: [
            require("../assets/hy/1d.png"),
            require("../assets/hy/1s.png"),
            require("../assets/hy/2s.png"),
            require("../assets/hy/3s.png"),
            require("../assets/hy/4s.png"),
            require("../assets/hy/2d.png"),
            require("../assets/hy/1s2.png"),
            require("../assets/hy/2s2.png"),
            require("../assets/hy/3s2.png"),
            require("../assets/hy/4s2.png"),
          ],
        },
        // --------幻奇---------------------------------------------------------------
        huanqi: {
          gyst:require("../assets/fm/xq.png"),
          ship: require("../assets/xq/1.mp4"),
          ztbg: require("../assets/xq/ztbg.png"),
          lx: require("../assets/xq/lx.png"),
          tu: [
            {
              imgd: require("../assets/xq/1d.png"),
              img1: require("../assets/xq/1s.png"),
              img2: require("../assets/xq/2s.png"),
              img3: require("../assets/xq/3s.png"),
              img4: require("../assets/xq/4s.png"),
            },
            {
              imgd: require("../assets/xq/2d.png"),
              img1: require("../assets/xq/1s2.png"),
              img2: require("../assets/xq/2s2.png"),
              img3: require("../assets/xq/3s2.png"),
              img4: require("../assets/xq/4s2.png"),
            },
            {
              imgd: require("../assets/xq/3d.png"),
              img1: require("../assets/xq/1s3.png"),
              img2: require("../assets/xq/2s3.png"),
              img3: require("../assets/xq/3s3.png"),
              img4: require("../assets/xq/4s3.png"),
            },
          ],
          tul: [
            require("../assets/xq/1d.png"),
            require("../assets/xq/1s.png"),
            require("../assets/xq/2s.png"),
            require("../assets/xq/3s.png"),
            require("../assets/xq/4s.png"),
            require("../assets/xq/2d.png"),
            require("../assets/xq/1s2.png"),
            require("../assets/xq/2s2.png"),
            require("../assets/xq/3s2.png"),
            require("../assets/xq/4s2.png"),
            require("../assets/xq/3d.png"),
            require("../assets/xq/1s3.png"),
            require("../assets/xq/2s3.png"),
            require("../assets/xq/3s3.png"),
            require("../assets/xq/4s3.png"),
          ],
        },
        // --------全速---------------------------------------------------------------
        quans: {
          gyst:require("../assets/fm/qs.jpg"),
          ship: require("../assets/qs/1.mp4"),
          ztbg: require("../assets/qs/ztbg.png"),
          lx: require("../assets/qs/lx.png"),
          tu: [
            {
              imgd: require("../assets/qs/1d.png"),
              img1: require("../assets/qs/1s.png"),
              img2: require("../assets/qs/2s.png"),
              img3: require("../assets/qs/3s.png"),
              img4: require("../assets/qs/4s.png"),
            },
            {
              imgd: require("../assets/qs/2d.png"),
              img1: require("../assets/qs/1s2.png"),
              img2: require("../assets/qs/2s2.png"),
              img3: require("../assets/qs/3s.png"),
              img4: require("../assets/qs/4s.png"),
            },
          ],
          tul: [
            require("../assets/qs/1d.png"),
            require("../assets/qs/1s.png"),
            require("../assets/qs/2s.png"),
            require("../assets/qs/3s.png"),
            require("../assets/qs/4s.png"),
            require("../assets/qs/2d.png"),
            require("../assets/qs/1s2.png"),
            require("../assets/qs/2s2.png"),
            require("../assets/qs/3s.png"),
            require("../assets/qs/4s.png"),
          ],
        },
      },
      // wax:[shijue,xiaoxian,hanyi,quans,hanyi],
    };
  },
  destroyed() {
    clearInterval(this.timer);
  },
  created() {
    var ww = this.$route.params.na;
    console.log(ww);
    if (ww == 0) {
      this.ymnr = this.sjz.shijue; //页面内容赋值
      window._czc.push(["_trackEvent", "点击", "供货商", "视觉", "1", "1"]);
    } else if (ww == 1) {
      this.ymnr = this.sjz.xiaoxian; //页面内容赋值
      window._czc.push(["_trackEvent", "点击", "供货商", "小仙", "1", "1"]);
    } else if (ww == 2) {
      this.ymnr = this.sjz.hanyi; //页面内容赋值
      window._czc.push(["_trackEvent", "点击", "供货商", "汉仪", "1", "1"]);
    } else if (ww == 3) {
      this.ymnr = this.sjz.quans; //页面内容赋值
      window._czc.push(["_trackEvent", "点击", "供货商", "全速", "1", "1"]);
    } else if (ww == 4) {
      this.ymnr = this.sjz.huanqi; //页面内容赋值
      window._czc.push(["_trackEvent", "点击", "供货商", "幻奇", "1", "1"]);
    }
    console.log(ww);
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
    // var ww=this.$route.params.na
    // console.log(ww)
    // if(ww==0){
    //    this.ymnr = this.sjz.shijue; //页面内容赋值

    // }else if(ww==1){
    //    this.ymnr = this.sjz.xiaoxian; //页面内容赋值
    // }else if(ww==2){
    //    this.ymnr = this.sjz.hanyi; //页面内容赋值
    // }else if(ww==3){
    //    this.ymnr = this.sjz.quans; //页面内容赋值
    // }else if(ww==4){
    //    this.ymnr = this.sjz.hanyi; //页面内容赋值
    // }
    // console.log(ww)

    this.mossz = this.ymnr.tu; //轮播图片
    this.tpyl = this.ymnr.tul; //轮播图片预览
    // this.mossz
    console.log(this.tpyl);
    this.spjs();
    this.pbl();
  },
  methods: {
    // 往期回顾的轮播----------------------------------------------------------------------------------------------------------------------------------------------
    //把拿到的数据分开放到两个数组中
    pbl() {
      this.moll = this.mossz.concat(this.mossz);
      console.log(this.moll);

      setTimeout(() => {
        this.jsul();
      }, 1000);
    },
    // 111往期回顾模块的点击图片放大预览
    fdtp(i) {
      console.log(i);
      var ass = 0;
      for (var a = 0; a < this.tpyl.length; a++) {
        if (this.tpyl[a] == i) {
          ass = a;
        }
      }
      console.log(ass);
      clearInterval(this.timer); //鼠标点击清除定时器
      var this_ = this;
      ImagePreview({
        images: this.tpyl,
        closeable: true,
        // showIndex:false,
        startPosition: ass,
        onClose() {
          console.log(147258);
          this_.timer = setInterval(() => {
            this_.move();
          }, 30);
        },
      });
    },
    //111往期回顾模块获取图片的长度
    jsul() {
      var oli = document.getElementsByClassName("oli");
      for (var i = 0; i < oli.length; i++) {
        this.lis += oli[i].offsetWidth;
        if (i == oli.length - 1) {
          this.tpgd();
          console.log(this.lis);
        }
      }
    },
    //111往期回顾模块把图片的长度赋值给父元素图片动起来
    tpgd() {
      console.log("循环完了", this.lis);
      var oul = document.getElementById("ul1");
      oul.style.width = this.lis + "px";
      this.timer = setInterval(() => {
        this.move();
      }, 30);
    },
    //111往期回顾模块让图片动起来
    move() {
      var oul = document.getElementById("ul1");
      if (oul.offsetLeft < -(oul.offsetWidth / 2)) {
        //向左滚动，当靠左的图4移出边框时
        oul.style.left = 0;
      }
      // if (oul.offsetLeft > 0) { //向右滚动，当靠右的图1移出边框时
      //     oul.style.left = -(oul.offsetWidth / 2) + 'px';
      // }
      oul.style.left = oul.offsetLeft + -1 + "px"; //向左滚动的时候为-2，向右滚动为正2
    },
    // -----------------------------------

    //花絮视频点击开始播放
    ksbfsp() {
      this.spbfs = 0;
      var spbf = document.getElementById("spbf");
      spbf.play();
    },
    //花絮视频播放结束后显示封面
    spjs() {
      var elevideo = document.getElementById("spbf");
      var this_ = this;
      elevideo.addEventListener(
        "ended",
        function () {
          //结束
          console.log("播放结束");
          this_.spbfs = 1;
        },
        false
      );
    },
  },
};
</script>
<style scoped>

/* ------------------------------------------------- */
/* ------------------------------------------------- */
.ul1 li .t2 .s1 {
  height: 1.45rem;
  left: 0.05rem;
  position: absolute;
  top: 0rem;
  width: 2.68rem;
  height: 1.41rem;
}
.ul1 li .t2 .s2 {
  height: 1.45rem;
  bottom: 0;
  left: 0.05rem;
  position: absolute;
  width: 2.68rem;
  height: 1.41rem;
}
.ul1 li .t2 {
  /* background-color: rgb(106, 190, 132); */
  position: relative;
  height: 2.9rem;
  width: 2.75rem;
  display: inline-block;
}
.ul1 li .t1 {
  width: 3.7rem;
  height: 2.9rem;
  display: inline-block;
}
.uli li .t2 {
  display: inline-block;
}
.ul1 li {
  /* display: flex; */

  text-align: left;
  display: inline-block;
  /* position: relative; */
  white-space: nowrap;
  height: 2.9rem;
  width: 9.28rem;
  /* background-color: rgb(105, 87, 87); */
}
.ul1 {
  width: 100%;
  height: 100%;
  /* overflow-x: auto; */
  /* overflow-X: hidden; */
  position: absolute;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.xhgd {
  overflow-x: auto;
  overflow-Y: hidden;
  /* overflow: hidden; */
  width: 6.9rem;
  height: 2.9rem;
  /* background-color: rgb(65, 66, 66); */
  position: absolute;
  left: 50%;
  top: 0rem;
  transform: translate(-50%);
}
.xhgds {
  /* overflow-x: auto; */
  /* overflow-Y: hidden; */
  overflow: hidden;
  width: 6.9rem;
  height: 1.47rem;
  /* background-color: aqua; */
  position: absolute;
  left: 50%;
  top: 1.47rem;
  transform: translate(-50%);
}
.lbrc {
  width: 7rem;
  top: 1rem;
  position: absolute;
}
/* ============================================================= */
/* ============================================================= */

.bgsc {
  position: relative;
  z-index: 10rem;
}
.lxfsk img {
  /* width: 1.87rem; */
  height: 2.7rem;

  position: absolute;
  /* left: 1rem; */
  transform: translate(-50%);
}
.lxfsk {
  overflow: hidden;
  /* width: 7rem; */
  height: 2.7rem;
  position: relative;
  /* background-color: royalblue; */
  left: 50%;
  transform: translate(-50%);
}
.bfkb {
  width: 1.2rem;
  top: 50%;
  position: absolute;
  height: 1.2rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bffm {
  width: 7rem;
  top: 0rem;
  position: absolute;
  height: 4.2rem;
  left: 0rem;
}
.smcs {
  width: 7rem;
  height: 4rem;
  position: absolute;
  /* top: 1.15rem; */
  /* background-color: rgba(1, 1, 1, 0.4); */
}
.spbf {
  width: 7rem;
  height: 4.72rem;
  position: absolute;
  /* top: 1.15rem; */
}
.hxsptit {
  height: 0.68rem;
  width: 2.93rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
}
.tbtb {
  width: 3.3rem;
  height: 0.6rem;
  top: 4.2rem;
  right: 0rem;
  position: absolute;
}
.zxxmp {
  width: 6.9rem;
  height: 2.95rem;
  position: absolute;
  left: 0rem;
  top: 0.88rem;
  /* transform: translate(-50%); */
}
.tdzx {
  width: 2.42rem;
  height: 0.64rem;
  position: absolute;
  left: 0rem;
}
.gdzx {
  width: 6.9rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  height: 4.6rem;
  /* background-color: salmon; */
}
.gytd .gytdtit {
  position: relative;
  width: 2.04rem;
  height: 0.62rem;
  /* left: 0; */
  text-align: left;
}
.gytd .gytdtp {
  width: 6.9rem;
  /* height: 2.82rem; */
  position: relative;
  /* top: 1rem; */
  left: 0;
}
.gytd {
  text-align: left;
  /* top: 1rem; */
  width: 6.9rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  /* height: 4.2rem; */
  /* background-color: salmon; */
}
.yydb {
  position: relative;
  bottom: -1rem;
  /* z-index: 0; */
  width: 100%;
  /* left: 0; */
}

.ybtc {
  overflow: hidden;
  margin-top: 0.4rem;
  width: 7rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  height: 4.69rem;
  /* background-color: salmon; */
}
.bgss {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 12;
}

.bg {
  /* background: url(../assets/pa/yy.png) no-repeat; */
  width: 100vw;
  position: relative;
  top: 0rem;
  left: 0;
  display: block;
  font-size: 0%;
  z-index: 11;
  /* min-height: 13rem; */
  /* background-size: cover; */
  /* background-position: center -0.7rem; */
  /* overflow: hidden; */
}
.wws{
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
</style>