import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import xx from '../views/xx.vue'
import yy from '../views/yy.vue'
import team from '../views/team.vue'
import supplier from '../views/supplier.vue'
import guest from '../views/guest.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/home',
        name: 'Home',
        component: Home
    }, {
        path: '/',
        name: 'xx',
        component: xx
    },
    {
        path: '/yy',
        name: 'yy',
        component: yy
    },
    {
        path: '/team/:na',
        name: 'team',
        component: team
    },
    {
        path: '/supplier/:na',
        name: 'supplier',
        component: supplier
    },
    {
        path: '/guest/:na',
        name: 'guest',
        component: guest
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import ( /* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    routes
})

export default router