<template>
  <div class="wws">
    <div class="bg">
      <img src="../assets/pa/jbjs.png" alt="" class="bgs" />
      <div class="neir">
        <!-- 主讲嘉宾介绍 -->
        <div class="ztbg">
          <div class="ztbgt">
               <img src="../assets/pa/xzsj.png" alt=""  /> 
               <div class="kuai">
                 <div class="os1">{{ymnr.ztbg}}</div>
                 <div class="os2">{{ymnr.ztbgw}}</div>
               </div>
          </div>
          <div class="grjs">
            <div class="grzt">
              {{ ymnr.sjs }}
            </div>
            <div class="bk2">
              {{ ymnr.jss }}
            </div>
            <div class="bk3">
              <img :src="ymnr.rwtx" alt="" class="zimg" />
              <div class="zd1">
                <div class="nam">{{ ymnr.nam }}</div>
                <div class="zhuwei">{{ ymnr.zhw }}</div>
                <img src="../assets/jbjs/xian.png" alt="" class="xian" />
                <div class="jies">
                  {{ ymnr.grjs }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 关于团队-->
        <div class="gytd" v-if="ymnr.tdna!=''">
          <img src="../assets/jbjs/gytdtit.png" alt="" class="gytdt" />
          <div class="gyddk">
            <div class="gytdtit">{{ ymnr.tdna }}</div>
            <div class="gyjss">
              {{ ymnr.tdjs }}
            </div>
          </div>
        </div>
        <!-- 轮播 -->
        <div class="gdzx" v-if="ymnr.tdna!=''">
          <img src="../assets/pa/tdzxxm.png" alt="" class="tdzx" />
          <div class="lbrc">
            <div class="gwhgk">
              <div class="xhgd" id="gdk">
                <ul id="ul1" class="ul1">
                  <li
                    class="oli"
                    v-for="(val, i) in moll"
                    :key="i"
                    :id="`ds${i}`"
                  >
                    <img
                      :src="val.imgd"
                      alt=""
                      class="t1"
                      @click="fdtp(val.imgd)"
                    />
                    <div class="t2">
                      <img
                        :src="val.img1"
                        alt=""
                        class="s1"
                        @click="fdtp(val.img1)"
                      />
                      <img
                        :src="val.img2"
                        alt=""
                        class="s2"
                        @click="fdtp(val.img2)"
                      />
                    </div>
                    <div class="t2">
                      <img
                        :src="val.img3"
                        alt=""
                        class="s1"
                        @click="fdtp(val.img3)"
                      />
                      <img
                        :src="val.img4"
                        alt=""
                        class="s2"
                        @click="fdtp(val.img4)"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <img src="../assets/pa/tbtb.png" alt="" class="tbtb"> -->
        </div>
        <div class="xjbtn">
          <router-link to="/yy">
            <img src="../assets/pa/yybtn.png" alt="" id="ljbtn" :class="{'klb' : ymnr.tdna==''}"/>
          </router-link>
        </div>
        <img src="../assets/pa/yydb.png" alt="" class="yydb" />
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      lis: 0, //所有图片的宽度
      moll: [],
      ymnr: {}, //页面的整个内容
      mossz: [], //轮播图中的图片
      tpyl: [], //图片预览
      tdjszxx: {
        //------胡君-------------------------------------------------------------------------------------------
        tdsj: {
          ztbg:"用户体验管理探索",
          ztbgw:"Exploration of user experience management",
          rwtx:require("../assets/jbtx/hj.png"),
          sjs: "12月19日 14:00-14:30", //时间
          jss: "人人都说体验，但在业务kpi与用户体验需要做出优先排序时，用户体验问题往往又是排在最后的，此次报告主要分享在银行背景下上百条业务线共同在一个app主题上经营上的用户体验管理探索之路。包括了用户体验问题的分类、定义、管理制度的落地等内容。", //简介
          nam: "胡君", //姓名
          zhw: "平安银行总行用户体验总监 \n  \n 总行用户体验专家组组长", //职位
          grjs: "2012年进入平安集团金融领域先后担任平安金融科技用户体验总监、平安付用户体验部副总经历，现任平安银行总行用户体验总监&总行用户体验专家组组长，负责平安银行零售侧客户的整体体验。深耕金融领域十年，致力通过科技手段改变金融服务的体验，让金融服务更加智能化、人性化。先后负责并参与过集团内超过50%应用的设计研发工作，集团第一批用户体验设计从业人员。", //个人简介
          tdna: "平安银行",
          tdjs: "PBDC由用户研究、产品设计、创意设计、IP设计、前端开发等150+成员组成。  团队致力于打造中国最卓越、全球领先的智能化零售银行，利用体验技术赋能产品、服务客户创造价值、不断探索更美好的金融体验。",
          tptd: [
            {
              imgd: require("../assets/pxxh/1d.png"),
              img1: require("../assets/pxxh/1s.png"),
              img2: require("../assets/pxxh/2s.png"),
              img3: require("../assets/pxxh/3s.png"),
              img4: require("../assets/pxxh/4s.png"),
            },
            {
              imgd: require("../assets/pxxh/2d.png"),
              img1: require("../assets/pxxh/1s2.png"),
              img2: require("../assets/pxxh/2s2.png"),
              img3: require("../assets/pxxh/3s2.png"),
              img4: require("../assets/pxxh/4s2.png"),
            },
            {
              imgd: require("../assets/pxxh/3d.png"),
              img1: require("../assets/pxxh/1s3.png"),
              img2: require("../assets/pxxh/2s3.png"),
              img3: require("../assets/pxxh/3s3.png"),
              img4: require("../assets/pxxh/4s3.png"),
            },
            {
              imgd: require("../assets/pxxh/4d.png"),
              img1: require("../assets/pxxh/1s4.png"),
              img2: require("../assets/pxxh/2s4.png"),
              img3: require("../assets/pxxh/3s4.png"),
              img4: require("../assets/pxxh/4s4.png"),
            },
            {
              imgd: require("../assets/pxxh/5d.png"),
              img1: require("../assets/pxxh/1s5.png"),
              img2: require("../assets/pxxh/2s5.png"),
              img3: require("../assets/pxxh/3s5.png"),
              img4: require("../assets/pxxh/4s5.png"),
            },
            {
              imgd: require("../assets/pxxh/6d.png"),
              img1: require("../assets/pxxh/1s6.png"),
              img2: require("../assets/pxxh/2s6.png"),
              img3: require("../assets/pxxh/3s6.png"),
              img4: require("../assets/pxxh/4s6.png"),
            },
            {
              imgd: require("../assets/pxxh/7d.png"),
              img1: require("../assets/pxxh/1s7.png"),
              img2: require("../assets/pxxh/2s7.png"),
              img3: require("../assets/pxxh/3s7.png"),
              img4: require("../assets/pxxh/4s7.png"),
            },
            {
              imgd: require("../assets/pxxh/8d.png"),
              img1: require("../assets/pxxh/1s8.png"),
              img2: require("../assets/pxxh/2s8.png"),
              img3: require("../assets/pxxh/3s.png"),
              img4: require("../assets/pxxh/4s.png"),
            },
          ],
          tul: [
            require("../assets/pxxh/1d.png"),
            require("../assets/pxxh/1s.png"),
            require("../assets/pxxh/2s.png"),
            require("../assets/pxxh/3s.png"),
            require("../assets/pxxh/4s.png"),
            require("../assets/pxxh/2d.png"),
            require("../assets/pxxh/1s2.png"),
            require("../assets/pxxh/2s2.png"),
            require("../assets/pxxh/3s2.png"),
            require("../assets/pxxh/4s2.png"),
            require("../assets/pxxh/3d.png"),
            require("../assets/pxxh/1s3.png"),
            require("../assets/pxxh/2s3.png"),
            require("../assets/pxxh/3s3.png"),
            require("../assets/pxxh/4s3.png"),
            require("../assets/pxxh/4d.png"),
            require("../assets/pxxh/1s4.png"),
            require("../assets/pxxh/2s4.png"),
            require("../assets/pxxh/3s4.png"),
            require("../assets/pxxh/4s4.png"),
            require("../assets/pxxh/5d.png"),
            require("../assets/pxxh/1s5.png"),
            require("../assets/pxxh/2s5.png"),
            require("../assets/pxxh/3s5.png"),
            require("../assets/pxxh/4s5.png"),
            require("../assets/pxxh/6d.png"),
            require("../assets/pxxh/1s6.png"),
            require("../assets/pxxh/2s6.png"),
            require("../assets/pxxh/3s6.png"),
            require("../assets/pxxh/4s6.png"),
            require("../assets/pxxh/7d.png"),
            require("../assets/pxxh/1s7.png"),
            require("../assets/pxxh/2s7.png"),
            require("../assets/pxxh/3s7.png"),
            require("../assets/pxxh/4s7.png"),
            require("../assets/pxxh/8d.png"),
            require("../assets/pxxh/1s8.png"),
            require("../assets/pxxh/2s8.png"),
            require("../assets/pxxh/3s.png"),
            require("../assets/pxxh/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //------唐卓娴-------------------------------------------------------------------------------------------
        tzt: {
                    ztbg:"陆金所从类固收到净值化的设计转型",
          ztbgw:"Lufax received the net design transformation from Class Solid",
          rwtx:require("../assets/jbtx/tz.png"),

          sjs: "12月19日 14:30-15:00", //时间
          jss: "过去10年，中国金融科技市场风云变幻，陆金所这家2011年成立的金融科技公司也基本完成了从主营类固收产品到净值化产品的艰难转型，并在2020年成功上市。本次分享将从设计师视角，回顾最近三年在支持战略及业务转型过程中获得的一些经验。", //简介
          nam: "唐卓娴", //姓名
          zhw: "陆金所产品体验部 交互设计经理", //职位
          grjs: "毕业于湖南大学，9年从业经验，曾供职于京东、一号店。2016年加入陆金所，在互联网金融设计方向积累了较为丰富的经验。", //个人简介
         tdna: "陆金所",
          tdjs: "陆金所是纽交所上市企业，为超4600万用户提供8600种理财产品及相关服务。设计团队规模约20人，由交互设计、UI视觉设计、营销视觉设计组成，在互联网投资理财设计领域经验丰富，专业创新是我们坚持的团队精神。",
          tptd: [
            {
              imgd: require("../assets/ljs/1d.png"),
              img1: require("../assets/ljs/1s.png"),
              img2: require("../assets/ljs/2s.png"),
              img3: require("../assets/ljs/3s.png"),
              img4: require("../assets/ljs/4s.png"),
            },
          ],
          tul: [
            require("../assets/ljs/1d.png"),
            require("../assets/ljs/1s.png"),
            require("../assets/ljs/2s.png"),
            require("../assets/ljs/3s.png"),
            require("../assets/ljs/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //------潘达-------------------------------------------------------------------------------------------
        pand: {
                    ztbg:"在线医疗如何构建与患者的信任感",
          ztbgw:"How to build trust with patients in online healthcare",
          rwtx:require("../assets/jbtx/pd.png"),

          sjs: "12月19日 15:10-15:40", //时间
          jss: "医患关系可谓是当今社会的一大难题，而医患纠纷的本质就是双方的不信任，那么如何构建医患信任关系，平安健康将从平台、医生、隐私保护和行业政策等方面进行发力，重塑用户信任心智，建立医患之间信任感，从而提升线上问诊的满意度和体验感", //简介
          nam: "潘达", //姓名
          zhw: "平安健康UED团队资深交互设计师", //职位
          grjs: "主要负责在线问诊、平安自聘医生、外部名医、健康药房以及CMS组件化等相关的交互设计工作。", //个人简介
            tdna: "平安健康互联网",
          tdjs: "平安健康作为港交所上市的互联网医疗第一股，累计注册用户数达4亿人。平台借助自有医疗团队7*24小时的全天候服务以及外部名医大咖的权威指导，用户足不出户即可体验到问诊、开方、用药指导、报告解读、随访关怀等各项服务。UED团队是由交互、UI、品牌设计师组成的41人的团队，为公司各项业务提供全方位的设计赋能。",
          tptd: [
            {
              imgd: require("../assets/pajk/1d.png"),
              img1: require("../assets/pajk/1s.png"),
              img2: require("../assets/pajk/2s.png"),
              img3: require("../assets/pajk/3s.png"),
              img4: require("../assets/pajk/4s.png"),
            },
          ],
          tul: [
            require("../assets/pajk/1d.png"),
            require("../assets/pajk/1s.png"),
            require("../assets/pajk/2s.png"),
            require("../assets/pajk/3s.png"),
            require("../assets/pajk/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //------朱娜-------------------------------------------------------------------------------------------
        zn: {
                    ztbg:"平安知鸟设计新生态建设",
          ztbgw:"Safe bird design new ecological construction",
          rwtx:require("../assets/jbtx/zn.png"),
          sjs: "12月19日 15:40-16:10", //时间
          jss: "伴随着数字化转型驱动生产方式的变革，企业级产品的交付成本与效率问题变得异常尖锐。本次分享，将介绍平安知鸟完善的设计生态体系和平台建设工作，分享设计团队如何通过设计系统化和工具化能力，为客户提供解决方案，也为平台自身解决设计交付的一致性和效率问题。", //简介
          nam: "朱娜", //姓名
          zhw: "平安国际智慧城智慧教育事业部产品部UED团队设计总监", //职位
          grjs: "十年以上B端产品设计经验，六年专注于平安知鸟SaaS平台，为集团内外多个大型企业提供培训解决方案，助力政企机构数字化转型", //个人简介
   tdna: "知鸟",
          tdjs: "知鸟设计体验中心，是平安国际智慧城市下一支具有完善设计生态的UX体验团队，具备规模化解决企业级产品设计交付的能力。主要负责平安知鸟智慧教育SaaS产品的体验研究和设计服务，致力于帮助企业快速构建「美好」、「简单」的数字体验。",
          tptd: [
            {
              imgd: require("../assets/zn/1d.png"),
              img1: require("../assets/zn/1s.png"),
              img2: require("../assets/zn/2s.png"),
              img3: require("../assets/zn/3s.png"),
              img4: require("../assets/zn/4s.png"),
            },
            {
              imgd: require("../assets/zn/2d.png"),
              img1: require("../assets/zn/1s2.png"),
              img2: require("../assets/zn/2s2.png"),
              img3: require("../assets/zn/3s.png"),
              img4: require("../assets/zn/4s.png"),
            },
          ],
          tul: [
            require("../assets/zn/1d.png"),
            require("../assets/zn/1s.png"),
            require("../assets/zn/2s.png"),
            require("../assets/zn/3s.png"),
            require("../assets/zn/4s.png"),
            require("../assets/zn/2d.png"),
            require("../assets/zn/1s2.png"),
            require("../assets/zn/2s2.png"),
            require("../assets/zn/3s.png"),
            require("../assets/zn/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------

        //------曾舒婷-------------------------------------------------------------------------------------------
        cst: {
                    ztbg:"体验设计如何驱动销售队伍的数字化转型",
          ztbgw:"How does experience design drive the digital transformation of the sales force",
          rwtx:require("../assets/jbtx/zst.png"),
          sjs: "12月19日 16:10-16:40", //时间
          jss: "传统保险业的数字化转型，是基于科技创新实现从线上保单服务到数据经营，同时为客户提供多方位的保险服务。销售队伍是连接客户和险企不可替代的角色。分享以产险团体业务实践为例，从销售队伍的视角，通过体验设计的方式驱动数字化转型。", //简介
          nam: "曾舒婷", //姓名
          zhw: "平安产险科技中心UI设计师", //职位
          grjs: "深入团体保险业务，关注设计体现商业价值，运用从交互设计、服务设计到视觉设计，通过设计为业务问题提供解决方案，驱动服务体验优化和业务增长。", //个人简介
         tdna: "平安产险科技体验设计团队",
          tdjs: "平安产险科技体验设计团队（PATD），负责平安产险科技生态体系下的用户及商业产品的体验设计。团队秉承“简单包容可进化”的设计理念，探索科技与商业的有机融合，创造更加美好的保险科技产品与服务，践行并赋能公司“科技+金融”的经营理念。",
          tptd: [
            {
              imgd: require("../assets/pacx/1d.png"),
              img1: require("../assets/pacx/1s.png"),
              img2: require("../assets/pacx/2s.png"),
              img3: require("../assets/pacx/3s.png"),
              img4: require("../assets/pacx/4s.png"),
            },
          ],
          tul: [
            require("../assets/pacx/1d.png"),
            require("../assets/pacx/1s.png"),
            require("../assets/pacx/2s.png"),
            require("../assets/pacx/3s.png"),
            require("../assets/pacx/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //------张传勇-------------------------------------------------------------------------------------------
        zcy: {
                    ztbg:"体验设计驱动增长（B端）",
          ztbgw:"Experience Design driven Growth (B-side)",
          rwtx:require("../assets/jbtx/zcy.png"),

          sjs: "12月20日 14:00-14:30", //时间
          jss: "在本次分享中，我将会结合相关实际案例，剖析体验设计在传统B端金融场景里如何逐步做好价值呈现。我把这个过程分为4个层面：体验支撑、体验共建、体验驱动、体验引领。通过案例的方式逐步呈现体验设计在每个层面的探索与实践。", //简介
          nam: "张传勇", //姓名
          zhw: "平安银行IT对公派驻WowDesign团队 资深体验设计师", //职位
          grjs: "专注体验设计在B端场景中的探索及实践、畅销书《一个APP的诞生2.0》联合作者、《体验设计案例课》联合作者", //个人简介
tdna: "WOW",
          tdjs: "WOW Design隶属于IT对公派驻科技团队，专注于平安银行B端场景的产品创新与体验设计服务。目前有设计师60+，工作职能涵盖交互设计、UI设计、体验评测、用户研究、体验布道等模块。利用体验设计思维与方法，深耕B端金融场景，助力业务上量。",
          tptd: [
            {
              imgd: require("../assets/yh/1d.png"),
              img1: require("../assets/yh/1s.png"),
              img2: require("../assets/yh/2s.png"),
              img3: require("../assets/yh/3s.png"),
              img4: require("../assets/yh/4s.png"),
            },
            {
              imgd: require("../assets/yh/2d.png"),
              img1: require("../assets/yh/1s2.png"),
              img2: require("../assets/yh/2s2.png"),
              img3: require("../assets/yh/3s2.png"),
              img4: require("../assets/yh/4s2.png"),
            },
          ],
          tul: [
            require("../assets/yh/1d.png"),
            require("../assets/yh/1s.png"),
            require("../assets/yh/2s.png"),
            require("../assets/yh/3s.png"),
            require("../assets/yh/4s.png"),
            require("../assets/yh/2d.png"),
            require("../assets/yh/1s2.png"),
            require("../assets/yh/2s2.png"),
            require("../assets/yh/3s2.png"),
            require("../assets/yh/4s2.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //------王倩-------------------------------------------------------------------------------------------
        wj: {
                    ztbg:"可视化与孪生数据的进阶探索及实践（B端）",
          ztbgw:"Advanced Exploration and Practice of Visualization and Twin data (B-end)",
          rwtx:require("../assets/jbtx/wq.png"),
          
          sjs: "12月20日 14:30-15:00", //时间
          jss: "随着数字设计的发展变革，挑战不单单来自对“可视化”和“数字孪生”等领域体验的极致追求。也对开发和设计师提出了更高的技能要求。此次分享，将介绍可视化和孪生数字的定义概念。并通过“集团信息安全CTF大屏设计”实践案例，从设计角度，了解孪生可视的新诉求、新趋势和新变化。", //简介
          nam: "王倩", //姓名
          zhw: "平安科技信息安全部  视觉设计专家", //职位
          grjs: "主要负责B端安全产品设计，拥有丰富的可视化设计经验，致力于提高集团信息安全团队的设计能力和用户体验。10年+设计从业经验，曾就职新浪和9YOU等互联网公司。善于从业务侧视角看待设计资源的组合投入策略，倡导从用户视角做轻量化的体验设计。", //个人简介
         tdna: "平安科技",
          tdjs: "XDC依托于平安集团强大的综合金融实力以及平安科技顶尖的技术背景，提供集团内外多领域体验研究及设计服务，如，企业经营数字化、金融云、信息安全等领域，我们致力于让科技+金融之路变得更加愉悦、美好。",
          tptd: [
            {
              imgd: require("../assets/pakj/1d.png"),
              img1: require("../assets/pakj/1s.png"),
              img2: require("../assets/pakj/2s.png"),
              img3: require("../assets/pakj/3s.png"),
              img4: require("../assets/pakj/4s.png"),
            },
          ],
          tul: [
            require("../assets/pakj/1d.png"),
            require("../assets/pakj/1s.png"),
            require("../assets/pakj/2s.png"),
            require("../assets/pakj/3s.png"),
            require("../assets/pakj/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //------姜晶晶-------------------------------------------------------------------------------------------
        jjj: {
           ztbg:"打造用户与品牌创新的共创体验",
          ztbgw:"Create user and brand innovation co-creation experience",
          rwtx:require("../assets/jbtx/cjj.png"),

          sjs: "12月20日 15:10-15:40", //时间
          jss: "营销5.0时代，以人为本的运营时代到来。 “让用户获得更好的体验，是用户运营生意最为核心的问题”。新用户、新场景、新的关系连接对品牌提出了更高的要求。在当前的环境变化下，平安证券如何用“体验官计划” 探索综金协同的新模式，打造全新的品牌客户体验。", //简介
          nam: "姜晶晶", //姓名
          zhw: "平安证券UED负责人", //职位
          grjs: "参与平安证券线上互联网业务团队搭建，主导全平台体验框架搭建和规范制定。参与过平安证券全链路体验流程分析。在产品、多业态全链路体验层面均有丰富的实战经验及独特见解。曾在ebay易趣任设计主管、携程任机票事业部交互设计负责人，拥有大量项目实践经验。擅长大中型电子商务平台、OTA、移动互联网，金融互联网的产品体验设计，金融互联网、移动电商设计专家，拥有10余年互联网设计和管理经验。", //个人简介
          tdna: "平安证券",
          tdjs: "平安证券经纪用户体验团队主要负责平安证券股票、理财、期货、两融等线上线下各类业务的产品及营销的设计体验服务，专注于产品设计、交互设计、体验设计领域的不断实践和创新。旨在打造平安综合金融服务战略下智能化的证券服务平台。",
          tptd: [
            {
              imgd: require("../assets/pazj/1d.png"),
              img1: require("../assets/pazj/1s.png"),
              img2: require("../assets/pazj/2s.png"),
              img3: require("../assets/pazj/3s.png"),
              img4: require("../assets/pazj/4s.png"),
            },
          ],
          tul: [
            require("../assets/pazj/1d.png"),
            require("../assets/pazj/1s.png"),
            require("../assets/pazj/2s.png"),
            require("../assets/pazj/3s.png"),
            require("../assets/pazj/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //------董帅-------------------------------------------------------------------------------------------
        ds: {
                    ztbg:"国际大赛申奖流程与介绍",
          ztbgw:"International competition application process and introduction",
          rwtx:require("../assets/jbtx/ds.png"),

          sjs: "12月19日 15:40-16:10", //时间
          jss: "近两年，国内越来越重视体验设计环节，为此设置了很多奖项，我们也进行了多项知名奖项的申报。本次，我将以IF获奖的“AI客户经理（小安）”为例讲为大家分享一下奖项的申请流程，希望帮助集团未来不仅仅在设计奖，且在其他更多的金融相关奖项中获得更多成功。", //简介
          nam: "董帅", //姓名
          zhw: "平安银行基础零售设计团队 体验研究员", //职位
          grjs: "毕业于波士顿大学，曾供职于益普索，两年用户体验研究经验。现负责平安银行财富业务线用户研究及全站文案规范工作。", //个人简介
                    tdna: "平安银行",
          tdjs: "PBDC由用户研究、产品设计、创意设计、IP设计、前端开发等150+成员组成。  团队致力于打造中国最卓越、全球领先的智能化零售银行，利用体验技术赋能产品、服务客户创造价值、不断探索更美好的金融体验。",
          tptd: [
            {
              imgd: require("../assets/pxxh/1d.png"),
              img1: require("../assets/pxxh/1s.png"),
              img2: require("../assets/pxxh/2s.png"),
              img3: require("../assets/pxxh/3s.png"),
              img4: require("../assets/pxxh/4s.png"),
            },
            {
              imgd: require("../assets/pxxh/2d.png"),
              img1: require("../assets/pxxh/1s2.png"),
              img2: require("../assets/pxxh/2s2.png"),
              img3: require("../assets/pxxh/3s2.png"),
              img4: require("../assets/pxxh/4s2.png"),
            },
            {
              imgd: require("../assets/pxxh/3d.png"),
              img1: require("../assets/pxxh/1s3.png"),
              img2: require("../assets/pxxh/2s3.png"),
              img3: require("../assets/pxxh/3s3.png"),
              img4: require("../assets/pxxh/4s3.png"),
            },
            {
              imgd: require("../assets/pxxh/4d.png"),
              img1: require("../assets/pxxh/1s4.png"),
              img2: require("../assets/pxxh/2s4.png"),
              img3: require("../assets/pxxh/3s4.png"),
              img4: require("../assets/pxxh/4s4.png"),
            },
            {
              imgd: require("../assets/pxxh/5d.png"),
              img1: require("../assets/pxxh/1s5.png"),
              img2: require("../assets/pxxh/2s5.png"),
              img3: require("../assets/pxxh/3s5.png"),
              img4: require("../assets/pxxh/4s5.png"),
            },
            {
              imgd: require("../assets/pxxh/6d.png"),
              img1: require("../assets/pxxh/1s6.png"),
              img2: require("../assets/pxxh/2s6.png"),
              img3: require("../assets/pxxh/3s6.png"),
              img4: require("../assets/pxxh/4s6.png"),
            },
            {
              imgd: require("../assets/pxxh/7d.png"),
              img1: require("../assets/pxxh/1s7.png"),
              img2: require("../assets/pxxh/2s7.png"),
              img3: require("../assets/pxxh/3s7.png"),
              img4: require("../assets/pxxh/4s7.png"),
            },
            {
              imgd: require("../assets/pxxh/8d.png"),
              img1: require("../assets/pxxh/1s8.png"),
              img2: require("../assets/pxxh/2s8.png"),
              img3: require("../assets/pxxh/3s.png"),
              img4: require("../assets/pxxh/4s.png"),
            },
          ],
          tul: [
            require("../assets/pxxh/1d.png"),
            require("../assets/pxxh/1s.png"),
            require("../assets/pxxh/2s.png"),
            require("../assets/pxxh/3s.png"),
            require("../assets/pxxh/4s.png"),
            require("../assets/pxxh/2d.png"),
            require("../assets/pxxh/1s2.png"),
            require("../assets/pxxh/2s2.png"),
            require("../assets/pxxh/3s2.png"),
            require("../assets/pxxh/4s2.png"),
            require("../assets/pxxh/3d.png"),
            require("../assets/pxxh/1s3.png"),
            require("../assets/pxxh/2s3.png"),
            require("../assets/pxxh/3s3.png"),
            require("../assets/pxxh/4s3.png"),
            require("../assets/pxxh/4d.png"),
            require("../assets/pxxh/1s4.png"),
            require("../assets/pxxh/2s4.png"),
            require("../assets/pxxh/3s4.png"),
            require("../assets/pxxh/4s4.png"),
            require("../assets/pxxh/5d.png"),
            require("../assets/pxxh/1s5.png"),
            require("../assets/pxxh/2s5.png"),
            require("../assets/pxxh/3s5.png"),
            require("../assets/pxxh/4s5.png"),
            require("../assets/pxxh/6d.png"),
            require("../assets/pxxh/1s6.png"),
            require("../assets/pxxh/2s6.png"),
            require("../assets/pxxh/3s6.png"),
            require("../assets/pxxh/4s6.png"),
            require("../assets/pxxh/7d.png"),
            require("../assets/pxxh/1s7.png"),
            require("../assets/pxxh/2s7.png"),
            require("../assets/pxxh/3s7.png"),
            require("../assets/pxxh/4s7.png"),
            require("../assets/pxxh/8d.png"),
            require("../assets/pxxh/1s8.png"),
            require("../assets/pxxh/2s8.png"),
            require("../assets/pxxh/3s.png"),
            require("../assets/pxxh/4s.png"),
          ],
        },
        //-------------------------------------------------------------------------------------------------
        //------钟承东-------------------------------------------------------------------------------------------
        zcd: {
          ztbg:"数字化时代下的全面体验管理",
          ztbgw:"Total experience management in the digital age",
          rwtx:require("../assets/jbtx/zcd.png"),

          sjs: "12月19日 10:30-11:00", //时间
          jss: "数字化转型是当前企业发展中的关键话题。但转型的根本并非数字技术，而是企业管理思想及运营模式的转变。而在这个转型的过程中，人及其体验将成为我们面对各种不确定性的本源逻辑。“以用户为中心，以体验为视角，以场景为价值，以数据为资产”，通过数字化手段，围绕用户价值完成从数据获取、洞察以及行动的闭环，从而构建不断迭代成长的全面体验管理体系，促进用户与企业“心与心”的连接。", //简介
          nam: "钟承东", //姓名
          zhw: "益普索中国用户体验研究院 院长  UXPA中国 主席", //职位
          grjs: "擅长于从“以用户为中心”的设计到运营端到端的用户体验工作。倡导“双数据”驱动的体验管理及创新实践，积极推进数字化体验解决方案，助力企业数字化转型。数百个电商、金融、电信和科技等用户体验研究、设计和咨询项目经验。", //个人简介
          tdna: "", //团队名字
          tdjs: "", //团队介绍
          tptd: [

          ], //轮播图片
          tul: [
          ], //图片预览图
        },
        //-------------------------------------------------------------------------------------------------
              //------朱斌-------------------------------------------------------------------------------------------
        zs: {
                    ztbg:"用创新塑造新时代的B端产品",
          ztbgw:"Use innovation to shape b-end products of the new era",
          rwtx:require("../assets/jbtx/zf.png"),
          sjs: "12月20日 11:20-11:50", //时间
          jss: "B端企业的数字化转型既是国家战略转型的国策，也是产业互联网发展必然的趋势。毫无疑问，B端产品是当下的行业风口。B端的风口虽在，但近10年移动互联网高速发展的经验并不完全适用于B端企业。本次分享将介绍明星级企业协作产品-飞书在B端产品上的创新和最佳实践。", //简介
          nam: "朱斌", //姓名
          zhw: "字节跳动飞书Lark Design设计总监", //职位
          grjs: "光华龙腾奖-中国服务业设计十大杰出青年 中国设计青年百人榜获奖者", //个人简介
          tdna: "", //团队名字
          tdjs: "", //团队介绍
          tptd: [
           
          ], //轮播图片
          tul: [
           
          ], //图片预览图
        },
        //-------------------------------------------------------------------------------------------------
       //------丁丽婷-------------------------------------------------------------------------------------------
        dlt: {
           ztbg:"适老化及无障碍改造助力重点受益群体共享数字生活",
          ztbgw:"Age-friendly and barrier-free transformation helps key beneficiary groups share digital life",
          rwtx:require("../assets/jbtx/dlt.png"),
          sjs: "12月19日 11:05-11:35", //时间
          jss: "党的十八大以来，党中央、国务院坚持以人民为中心的发展思想，高度重视残疾人、老年人等特殊群体权益保障。中国信息通信研究院在工业和信息化部的指导下，按《互联网应用适老化及无障碍改造专项行动方案》要求大力推动我国信息无障碍建设，助力缩小“数字鸿沟”。", //简介
          nam: "丁丽婷", //姓名
          zhw: "中国信息通信研究院产业与规划研究所", //职位
          grjs: "从事信息⽆障碍、信息化、数字化转型等领域的研究⼯作，参与⽀撑⼯信部制定《关于推进信息⽆障碍的指导意⻅》、《互联⽹应⽤适⽼化及⽆障碍改造专项⾏动⽅案》等政策⽂件，《移动互联⽹应⽤（APP）适⽼化通⽤设计规范》起草⼈。", //个人简介
          tdna: "", //团队名字
          tdjs: "", //团队介绍
          tptd: [
          
          ], //轮播图片
          tul: [
          
          ], //图片预览图
        },
        //-------------------------------------------------------------------------------------------------
         //------马亿原-------------------------------------------------------------------------------------------
        myy: {
                    ztbg:"推开汉字这扇门",
          ztbgw:"Open the door of Chinese characters",
          rwtx:require("../assets/jbtx/msy.png"),

          sjs: "12月20日 10:40-11:20", //时间
          jss: "分享汉字文化，诠释字体之美，叩开隐秘之门，逐一解读字体与世间万物千丝万缕的联系。", //简介
          nam: "⻢忆原", //姓名
          zhw: "汉仪字库COO", //职位
          grjs: "汉仪字库COO、汉仪字体之星⼤赛评委，⾃2010年进⼊汉仪字库后，便开始着⼿致⼒于推进汉字字库产品的创新提⾼、应⽤拓展和字体⽂化公益传播。同时，她也是中国出版协会装帧艺术⼯作委员会常务委员，中国⼯业设计协会理事，第三届中关村⽂化创意产业⼗⼤创新⼈物，中央企业⻘年五四奖章获得者。", //个人简介
          tdna: "", //团队名字
          tdjs: "", //团队介绍
          tptd: [
        
          ], //轮播图片
          tul: [
         
          ], //图片预览图
        },
        //-------------------------------------------------------------------------------------------------
      
        //------陈阳阳-------------------------------------------------------------------------------------------
        cyy: {
          ztbg:"中国平安国民品牌塑造",
          ztbgw:"China Ping an National brand building",
          rwtx:require("../assets/jbtx/cyy.png"),
          sjs: "12月20日 10:00-10:30", //时间
          jss: "集团国民品牌建设策略及规划、集团品牌与个金会波段式营销复盘及展望、集团品牌与opa合作探讨", //简介
          nam: "陈阳阳", //姓名
          zhw: "平安集团品牌部副总经理", //职位
          grjs: "2006年加⼊中国平安，⼀直从事品牌战略规划、品牌传播、整合⾏销、公共关系、企业⽂化、品牌管理等⽅⾯⼯作，深耕品牌领域多年，擅⻓⽤户沟通，成功打造系列优秀品牌案例，⻅证并参与中国平安品牌的成⻓与发展。", //个人简介
          tdna: "", //团队名字
          tdjs: "", //团队介绍
          tptd: [
          ], //轮播图片
          tul: [
         
          ], //图片预览图
        },
        //-------------------------------------------------------------------------------------------------
      
      }, //所有的数据信息
    };
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
    console.log(this.$route.params.na);
    // 0是钟承东zcd
    // 1是马亿原myy
    // 2是陈阳阳cyy
    // 3是胡君tdsj
    // 4是朱斌zs
    // 5是丁丽婷dlt
    // 6唐卓娴tzt
    // 7潘达pand
    // 8朱娜zn
    // 9曾舒婷cst
    // 10张传勇zcy
    // 11王倩wj
    // 12姜晶晶jjj
    // 13董帅ds
    if(this.$route.params.na==0){
      this.ymnr = this.tdjszxx.zcd;
    }else if(this.$route.params.na==1){
      this.ymnr = this.tdjszxx.myy;
    }else if(this.$route.params.na==2){
      this.ymnr = this.tdjszxx.cyy;
    }else if(this.$route.params.na==3){
      this.ymnr = this.tdjszxx.tdsj;
    }else if(this.$route.params.na==4){
      this.ymnr = this.tdjszxx.zs;
    }else if(this.$route.params.na==5){
      this.ymnr = this.tdjszxx.dlt;
    }else if(this.$route.params.na==6){
      this.ymnr = this.tdjszxx.tzt;
    }else if(this.$route.params.na==7){
      this.ymnr = this.tdjszxx.pand;
    }else if(this.$route.params.na==8){
      this.ymnr = this.tdjszxx.zn;
    }else if(this.$route.params.na==9){
      this.ymnr = this.tdjszxx.cst;
    }else if(this.$route.params.na==10){
      this.ymnr = this.tdjszxx.zcy;
    }else if(this.$route.params.na==11){
      this.ymnr = this.tdjszxx.wj;
    }else if(this.$route.params.na==12){
      this.ymnr = this.tdjszxx.jjj;
    }else if(this.$route.params.na==13){
      this.ymnr = this.tdjszxx.ds;
    }
    

    this.mossz = this.ymnr.tptd; //轮播图片
    this.tpyl = this.ymnr.tul; //轮播图片预览
    console.log(this.mossz);
    this.pbl();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    // 往期回顾的轮播----------------------------------------------------------------------------------------------------------------------------------------------
    //把拿到的数据分开放到两个数组中
    pbl() {
      this.moll = this.mossz.concat(this.mossz);
      console.log(this.moll);

      setTimeout(() => {
        this.jsul();
      }, 1000);
    },
    // 111往期回顾模块的点击图片放大预览
    fdtp(i) {
      console.log(i);
      var ass = 0;
      for (var a = 0; a < this.tpyl.length; a++) {
        if (this.tpyl[a] == i) {
          ass = a;
        }
      }
      console.log(ass);
      clearInterval(this.timer); //鼠标点击清除定时器
      var this_ = this;
      ImagePreview({
        images: this.tpyl,
        closeable: true,
        // showIndex:false,
        startPosition: ass,
        onClose() {
          console.log(147258);
          this_.timer = setInterval(() => {
            this_.move();
          }, 30);
        },
      });
    },
    //111往期回顾模块获取图片的长度
    jsul() {
      var oli = document.getElementsByClassName("oli");
      for (var i = 0; i < oli.length; i++) {
        this.lis += oli[i].offsetWidth;
        if (i == oli.length - 1) {
          this.tpgd();
          console.log(this.lis);
        }
      }
    },
    //111往期回顾模块把图片的长度赋值给父元素图片动起来
    tpgd() {
      console.log("循环完了", this.lis);
      var oul = document.getElementById("ul1");
      oul.style.width = this.lis + "px";
      this.timer = setInterval(() => {
        this.move();
      }, 30);
    },
    //111往期回顾模块让图片动起来
    move() {
      var oul = document.getElementById("ul1");
      if (oul.offsetLeft < -(oul.offsetWidth / 2)) {
        //向左滚动，当靠左的图4移出边框时
        oul.style.left = 0;
      }
      // if (oul.offsetLeft > 0) { //向右滚动，当靠右的图1移出边框时
      //     oul.style.left = -(oul.offsetWidth / 2) + 'px';
      // }
      oul.style.left = oul.offsetLeft + -1 + "px"; //向左滚动的时候为-2，向右滚动为正2
    },
    // ----------------------------------------------------------------------------------------------------------
  },
};
</script>
<style scoped>
.yydb {
  position: relative;
  bottom: -1rem;
  /* z-index: 0; */
  width: 100%;
  /* left: 0; */
}
.gyjss {
  width: 6.27rem;
  position: relative;
  font-size: 0.2rem;
  line-height: 0.38rem;
  margin-top: 0.1rem;
  color: #b8bdd7;
  left: 50%;
  transform: translate(-50%);
}
.gytdtit {
  width: 6.27rem;
  position: relative;
  font-size: 0.25rem;
  /* font-weight: bold; */
  color: rgb(255, 255, 255);
  left: 50%;
  transform: translate(-50%);
}
.gyddk {
  width: 6.9rem;
  /* height: 5rem; */
  border-radius: 0.1rem;
  padding-top: 0.1rem;
  padding-bottom: 6px;
  background-color: rgba(232, 233, 236, 0.1);
  border: 1px solid rgba(232, 233, 236, 0.1);
}
.jies {
  font-size: 0.22rem;
  line-height: 0.32rem;
  text-align: justify;
}
.xian {
  width: 2.86rem;
  display: block;
  margin-top: 0.23rem;
  margin-bottom: 0.23rem;
}
.zhuwei {
  margin-top: 0.18rem;
  width: 3rem;
  /* background-color: aliceblue; */
  font-size: 0.22rem;
  line-height: 0.32rem;
}
.zd1 .nam {
  position: relative;
  font-size: 0.3rem;
  font-weight: bold;
  width: 4rem;
  color: #3f9ff4;
}
.zd1 .jszw {
  margin-top: 0.5rem;
  width: 4rem;
  /* height: 1.26rem; */
  background-color: rgb(187, 187, 187);
}
.zd1 {
  color: #b8bdd7;
  width: 4rem;
  /* background-color: bisque; */
  /* height: 4rem; */
  position: relative;
  left: 2.4rem;
  display: inline-block;
}
.bk3 .zimg {
  width: 2.4rem;
  top: -0.2rem;
  /* transform: translateY(-50%); */
  position: absolute;
  
}
.bk3 {
  min-height: 4rem;
  width: 6.4rem;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-top: 0.6rem;
  /* height: rem; */
  /* background-color: violet; */
}
.bk2 {
  width: 6.27rem;
  line-height: 0.38rem;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  font-size: 0.22rem;
  color: #b8bdd7;
  letter-spacing: 0.02rem;
  text-align: justify;
}
.grzt {
  width: 6.27rem;
  height: 0.84rem;
  /* background-color: rgba(175, 120, 120,0.3); */
  position: relative;
  left: 50%;
  transform: translate(-50%);
  font-size: 0.25rem;
  color: #b8bdd7;
  line-height: 0.84rem;
}
.grjs {
  /* text-align: center; */
  width: 6.9rem;
  /* height: 5rem; */
  background-color: rgba(232, 233, 236, 0.1);
  border: 1px solid rgba(232, 233, 236, 0.1);
  border-radius: 5px;
  padding-bottom: 0.2rem;
}
/* ------------------------------------------------- */
.ul1 li .t2 .s1 {
  height: 1.45rem;
  left: 0.05rem;
  position: absolute;
  top: 0rem;
  width: 2.68rem;
  height: 1.41rem;
}
.ul1 li .t2 .s2 {
  height: 1.45rem;
  bottom: 0;
  left: 0.05rem;
  position: absolute;
  width: 2.68rem;
  height: 1.41rem;
}
.ul1 li .t2 {
  /* background-color: rgb(106, 190, 132); */
  position: relative;
  height: 2.9rem;
  width: 2.75rem;
  display: inline-block;
}
.ul1 li .t1 {
  width: 3.7rem;
  height: 2.9rem;
  display: inline-block;
}
.uli li .t2 {
  display: inline-block;
}
.ul1 li {
  /* display: flex; */

  text-align: left;
  display: inline-block;
  /* position: relative; */
  white-space: nowrap;
  height: 2.9rem;
  width: 9.28rem;
  /* background-color: rgb(105, 87, 87); */
}
.ul1 {
  width: 100%;
  height: 100%;
  /* overflow-x: auto; */
  /* overflow-X: hidden; */
  position: absolute;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.xhgd {
  overflow-x: auto;
  overflow-Y: hidden;
  /* overflow: hidden; */
  width: 6.9rem;
  height: 2.9rem;
  /* background-color: rgb(65, 66, 66); */
  position: absolute;
  left: 50%;
  top: 0rem;
  transform: translate(-50%);
}
.xhgds {
  /* overflow-x: auto; */
  /* overflow-Y: hidden; */
  overflow: hidden;
  width: 6.9rem;
  height: 1.47rem;
  /* background-color: aqua; */
  position: absolute;
  left: 50%;
  top: 1.47rem;
  transform: translate(-50%);
}
.lbrc {
  width: 7rem;
  top: 1rem;
  position: absolute;
}
/* ============================================================= */

#ljbtn {
  width: 6rem;
}
.klb{
  margin-top: 0.92rem;
  margin-bottom: 1rem;
}
.xjbtn {
  left: 50%;
  transform: translate(-50%);
  position: relative;
  width: 7rem;
  /* height: 1rem; */
  margin-top: 0.5rem;
  /* background-color: yellow; */
}
.zxxmp {
  width: 6.9rem;
  height: 2.95rem;
  position: absolute;
  left: 0rem;
  top: 0.88rem;
  /* transform: translate(-50%); */
}
.tdzx {
  width: 2.42rem;
  height: 0.64rem;
  position: absolute;
  left: 0rem;
}
.gdzx {
  width: 6.9rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  height: 4.6rem;
  margin-top: 0.5rem;
  /* background-color: salmon; */
}
.gytd .gytdt {
  position: relative;
  /* width: 6.9rem; */
  height: 0.6rem;
  left: 0;
}
.gytd {
  text-align: left;
  /* top: 1rem; */
  width: 6.9rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  /* height: 4.2rem; */
  margin-top: 0.72rem;
  /* background-color: salmon; */
}
.ztbgt {
  margin-bottom: 0.3rem;
  position: relative;
  /* width: 6.9rem; */
  /* height: 0.6rem; */
  /* left: 0; */
  /* top: 0.55rem; */
  /* background-color: red; */
}
.ztbgt .kuai{
  /* position: absolute; */
  left: 0.2rem;
  width: 7rem;
  /* height: 0.6rem; */
  /* background-color: rgb(138, 146, 153); */
  font-size: 0.3rem;
}
.ztbgt .kuai .os1{
  color: white;
  font-weight: bold;
  position: relative;
  /* position: absolute; */
  top: -0.08rem;
  margin-left: 0.2rem;
  font-size: 0.32rem;
}
.ztbgt .kuai .os2{
  color: white;
  opacity: 0.3;
  font-weight: lighter;
  /* font-weight: bol; */
  /* position: absolute; */
    margin-left: 0.2rem;
  /* top: 0.36rem; */
  font-size: 0.2rem;
}
.ztbgt img{
  position: absolute;
  height: 0.25rem;
  top: 0.01rem;
}
.ztbg {
  text-align: left;
  position: relative;
  margin-top: 1rem;
  width: 6.9rem;
  /* height: 8.56rem; */
  /* background-color: rgba(232, 233, 236, 0.1);
  border:1px solid  rgba(232, 233, 236, 0.1);
  border-radius: 5px; */
  left: 50%;
  transform: translate(-50%);
}
.neir {
  position: absolute;
  top: 0rem;
  width: 100%;
}
.bgs {
  position: relative;
  width: 100%;
}
.bg {
  position: absolute;
  width: 100%;
  height: 100vh;
}
.wws {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
</style>