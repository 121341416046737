<template>
  <div class="bg">
    <img class="bgm" src="../assets/pa/bg.png" alt="" />
    <!-- 活动详情 -->
    <!-- <div class="jsxqnr" v-if="jsnr == 1">
      <img src="../assets/pa/wzjs2.png" alt="" class="opags2" />
    </div> -->
    <!-- <img class="bgms" src="../assets/pa/gbb.png" alt="" /> -->

    <div class="nrqy">
      <!-- 顶部logo -->
      <div class="tibb">
        <img src="../assets/pa/logo.png" alt="" class="logo" />
        <img src="../assets/pa/btit.png" alt="" class="btit" />
      </div>
      <!-- 大会直播模块 -->
      <div class="dhkk" v-if="zbzt==1">
        <img src="../assets/pa/dhk.png" alt="" class="dhk" />
        <img src="../assets/pa/yuewan.png" alt="" class="yuwan" />
        <img src="../assets/pa/dhbtn.png" alt="" class="yubtn" @click="tcyy" v-if="wyy==0" />
        <img src="../assets/pa/yycg.png" alt="" class="yubtnc" v-if="wyy==1" />
        <!-- <span class="yurs">已有{{ yxrs }}人预约成功</span> -->
        <span class="yurs">预约成功后请您刷新页面观看直播</span>
                <!-- <img src="../assets/pa/dhk.png" alt="" class="dhk" />
        <img src="../assets/pa/yuewan.png" alt="" class="yuwan" />
        <img src="../assets/pa/zzzb.png" alt="" class="yubtn" @click="zzzb" />
        <span class="yurs">点击观看</span> -->
      </div>
       <div class="dhkk" v-if="zbzt==2">
        <img src="../assets/pa/dhk.png" alt="" class="dhk" />
        <img src="../assets/pa/yuewan.png" alt="" class="yuwan" />
        <img src="../assets/pa/zzzb.png" alt="" class="yubtn" @click="zzzb" />
        <span class="yurs">点击观看</span>
      </div>
      <div class="dhkk" v-if="zbzt==3">
        <img src="../assets/pa/dhk.png" alt="" class="dhk" />
        <img src="../assets/pa/zbjs.png" alt="" class="zbjs" />
      </div>
      <div class="dhkk" v-if="zbzt==4">
        <img src="../assets/pa/dhk.png" alt="" class="dhk" />
        <img src="../assets/mtzl.png" alt="" class="zbjs" />
      </div>
     <div class="dhkk" v-if="zbzt==5">
        <img src="../assets/pa/dhk.png" alt="" class="dhk" />
        <img src="../assets/pa/yuewan.png" alt="" class="yuwan" />
        <img src="../assets/pa/ddhf.png" alt="" class="yubtns" />
        <span class="yurs">直播结束 明年再会</span>
      </div>
      <div class="dhkk" v-if="zbzt==6">
        <img src="../assets/pa/dhk.png" alt="" class="dhk" />
        <img src="../assets/pa/yuewan.png" alt="" class="yuwan" />
        <img src="../assets/pa/gkhf.png" alt="" class="yubtn" @click="zzzsb" />
        <span class="yurs">直播结束 明年再会</span>
      </div>
      <div class="dhkk" v-if="zbzt==7">
        <img src="../assets/pa/dhk.png" alt="" class="dhk" />
        <img src="../assets/pa/zbjs.png" alt="" class="zbjs" />
        <div class="possk">       
           <img src="../assets/pa/gk19h.png" alt="" class="sbtnn sbtn1" @click="hf19"/>
           <img src="../assets/pa/gk20h.png" alt="" class="sbtnn sbtn2" @click="hf20"/></div>
      </div>
      
      <!-- 关于OPA -->
      <div class="gyopa">
        <img src="../assets/pa/gyOA.png" alt="" class="opags" />
        <!-- <img src="../assets/pa/wzjs.png" alt="" class="opags1" /> -->
        <div class="opags1">ONE PINGAN（OPA design）组织介绍：该组织由平安个金会（平安集团个人客户综合金融管理委员会）发起，平安集团品宣指导，由平安银行、陆金所、平安⾦管家、金融壹账通、平安科技、平安证券、壹钱包、平安好医生、平安智慧城市、平安好车主等10家子公司共同参与的设计组织。从集团品牌共性、体验一致性的角度， 通过求同存异的原则，一起打造一个可持续迭代的设计系统、一个活力有创造性的设计组织。</div>
      </div>
      <!-- 活动详情 -->
      <!-- <div class="jsxqnr touming" v-if="jsnr == 1">
        <img src="../assets/pa/wzjs2.png" alt="" class="opags2" />
      </div> -->
      <!-- 再临领导 -->
      <div class="zlldk">
        <img src="../assets/pa/zwldtit.png" alt="" class="zlld" />
        <img src="../assets/pa/zwld.png" alt="" class="zllds" />
      </div>
      <!-- 嘉宾介绍 -->
      <div class="jbjs">
        <!-- <input type="text"> -->
        <div class="titks">
        <img src="../assets/pa/jbjstit.png" alt="" class="jbjstit" />
        </div>
        <!-- <img src="../assets/pa/jbjstit.png" alt="" class="jbjstit" /> -->
        <div class="jbjck">
          <div class="xqyms">
            <!-- <img :src="xsjbx.img" alt=""  @click="tcxqjb"/> -->
            <!-------------------------------------------------  -->
            <van-swipe
              :duration="500"
              :autoplay="3000"
              indicator-color="white"
              :height="132"
              :show-indicators="false"
              ref="swiper"
              @change="onChange"
              style="height:100%"
            >
              <van-swipe-item v-for="(val, i) in jbjs" :key="i" style="height:100%">
                <img :src="val.imgz" alt="" @click="tcxqjb(i)" style="height:2.4rem" />
              </van-swipe-item>
            </van-swipe>
            <!-------------------------------------------------  -->
          </div>
          <div class="xbxqt">
            <img
              :src="jbjss==i ? val.img :val.imgx"
              alt=""
              v-for="(val, i) in jbjs"
              :key="i"
              @click="cktxx(val, i)"
            />
          </div>
          <img src="../assets/pa/jbjstb.png" alt="" class="jbjstb" />
        </div>
      </div>
      <!-- 大会议程 -->
      <div class="dhyy">
        <img src="../assets/pa/dhyytit.png" alt="" class="jbjstit" />
        <span :class="{ dhyysj: dhycjs == 1 }" @click="dyyysj1" id="dhyysj1"
          >12.19</span
        >
        <span :class="{ dhyysj: dhycjs == 2 }" @click="dyyysj2" id="dhyysj2"
          >12.20</span
        >
        <img src="../assets/pa/dhyyx.png" alt="" class="dhyyx" />
        <img
          src="../assets/pa/dhyyxs.png"
          alt=""
          class="dhyyxs"
          v-if="dhycjs == 1"
        />
        <img
          src="../assets/pa/dhyyxs.png"
          alt=""
          class="dhyyxs2"
          v-if="dhycjs == 2"
        />
        <img
          src="../assets/pa/dhyc19.png"
          alt=""
          class="dhyyt1"
          v-if="dhycjs == 1"
        />
        <div class="zjjs19" v-if="dhycjs == 1">
          <!-- <div class="ds1" @click="tz19(1)"></div> -->
          <div class="ds2" @click="tz19(3)"></div>
          <div class="ds3" @click="tz19(0)"></div>
          <div class="ds4" @click="tz19(5)"></div>
          <div class="ds5" @click="tz19(3)"></div>
          <div class="ds6" @click="tz19(6)"></div>
          <div class="ds7" @click="tz19(7)"></div>
          <div class="ds8" @click="tz19(8)"></div>
          <div class="ds9" @click="tz19(9)"></div>
        </div>
        <img
          src="../assets/pa/dhyc20.png"
          alt=""
          class="dhyyt2"
          v-if="dhycjs == 2"
        />
        <div class="zjjs20" v-if="dhycjs == 2">
          <!-- <div class="ds1" @click="tz19(1)"></div> -->
          <!-- <div class="ds2" @click="tz19(2)"></div> -->
          <div class="ds3" @click="tz19(2)"></div>
          <div class="ds4" @click="tz19(1)"></div>
          <div class="ds5" @click="tz19(4)"></div>
          <div class="ds6" @click="tz19(10)"></div>
          <div class="ds7" @click="tz19(11)"></div>
          <div class="ds8" @click="tz19(12)"></div>
          <div class="ds9" @click="tz19(13)"></div>
        </div>
      </div>
      <!-- 优质供货商 -->
      <div class="yzghs">
         <img
          src="../assets/pa/ghstt.png"
          alt=""
          class="yzghstt"
        />
        <img
          src="../assets/pa/yzghs.png"
          alt=""
          class="yzghstp"
    
        />
        <div class="yzghstp">
            <div class="d1c" @click="ghstz(0)"></div>
            <div class="d2c" @click="ghstz(1)"></div>
            <div class="d3c" @click="ghstz(2)"></div>
            <div class="d4c" @click="ghstz(3)"></div>
            <div class="d5c" @click="ghstz(4)"></div>
            
        </div>
      </div>
      <!-- OPA组织成员 -->
      <div class="zzcy">
        <img src="../assets/pa/zzcy.png" alt="" class="zzcytp" />
        <!-- <div class="syryk" @click="zzcyym">
          <div class="zuo" id="zuo">
            <img
              :src="val.img"
              alt=""
              v-for="(val, i) in zzcy1"
              :key="i"
              :id="`gdz${i}`"
            />
          </div>
          <div class="you">
            <img
              :src="val.img"
              alt=""
              v-for="(val, i) in zzcy2"
              :key="i"
              :id="`gdz2${i}`"
            />
          </div>
        </div> -->
      </div>
      <!-- 往期回顾 -->
      <div class="gwhg">
        <img src="../assets/pa/gwhgtit.png" alt="" class="gwhgtit" />
                <!-- ``````````````````````````````````````````````````````` -->
        <div class="lbrc">
          <div class="gwhgk">
            <div class="xhgd" id="gdk">
              <ul id="ul1" class="ul1">
                <li
                  class="oli"
                  v-for="(val, i) in moll"
                  :key="i"
                  :id="`ds${i}`"
                >
                  <img
                    :src="val.imgd"
                    alt=""
                    class="t1"
                    @click="fdtp(val.imgd)"
                  />
                  <div class="t2">
                    <img
                      :src="val.img1"
                      alt=""
                      class="s1"
                      @click="fdtp(val.img1)"
                    />
                    <img
                      :src="val.img2"
                      alt=""
                      class="s2"
                      @click="fdtp(val.img2)"
                    />
                  </div>
                  <div class="t2">
                    <img
                      :src="val.img3"
                      alt=""
                      class="s1"
                      @click="fdtp(val.img3)"
                    />
                    <img
                      :src="val.img4"
                      alt=""
                      class="s2"
                      @click="fdtp(val.img4)"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- ``````````````````````````````````````````````````````` -->
      </div>
      <!-- 花絮视频 -->
      <div class="hxsp">
        <img src="../assets/pa/hxsptit.png" alt="" class="hxsptit" />
        <div class="spbf">
          <video
            src="../assets/let_it_go.mp4"
            width="350"
            height="200"
            poster="../assets/pa/bffm.png"
            controls
            preload
            
            id="spbf"
          ></video>
        </div>
        <div class="smcs" v-if="spbfs == 1">
          <img src="../assets/pa/bffm.png" alt="" class="bffm" />
          <img
            src="../assets/pa/bfkb.png"
            alt=""
            class="bfkb"
            @click="ksbfsp"
          />
        </div>
      </div>
      <!-- 大会地点 -->
      <div class="dhdd">
        <img src="../assets/pa/dhddtit.png" alt="" class="dhddtit" />
        <img src="../assets/pa/dhdds.png" alt="" class="dhdds" />
        <div class="ditu" id="container"></div>
        <img src="../assets/pa/dhdddj.png" alt="" class="ksdh" @click="tztu1" />
        <div class="ditu2" id="container2"></div>
        <img
          src="../assets/pa/dhdddj.png"
          alt=""
          class="ksdh2"
          @click="tztu2"
        />
        <img src="../assets/pa/gzh1.png" alt="" class="gzh1" />
        <img src="../assets/pa/gzh2.png" alt="" class="gzh2" />
        <img src="../assets/pa/dhdd1.png" alt="" class="dhdd1">
        <img src="../assets/pa/dhdd2.png" alt="" class="dhdd2">
        <!-- <img src="../assets/pa/gzhs.png" alt="" class="gzhs" /> -->
      </div>
      <!-- 平安参会中心提示 -->
      <div class="chzx">
        <img src="../assets/pa/pazhgs.png" alt="" class="chzxt" />
        <img src="../assets/pa/pazhgser.png" alt="" class="chzxter" />
        <div class="chzxts">
            <div @click="tdjm(9)" class="tdjs1 "></div>
            <div @click="tdjm(1)" class="tdjs2 "></div>
            <div @click="tdjm(2)" class="tdjs3 "></div>
            <div @click="tdjm(3)" class="tdjs4 "></div>
            <div @click="tdjm(4)" class="tdjs5 "></div>
            <div @click="tdjm(5)" class="tdjs6 "></div>
            <div  class="tdjs7 "></div>
            <div @click="tdjm(6)" class="tdjs8 "></div>
            <div @click="tdjm(7)" class="tdjs9 "></div>
            <div @click="tdjm(8)" class="tdjs10"></div>
            <div @click="tdjm(5)" class="tdjs11"></div>
            <div @click="tdjm(6)" class="tdjs12"></div>
            <div @click="tdjm(9)" class="tdjs13"></div>
            <div @click="tdjm(3)" class="tdjs14"></div>
            <div @click="tdjm(10)" class="tdjs15"></div>
            <div @click="tdjm(8)" class="tdjs16"></div>
            <div @click="tdjm(4)" class="tdjs17"></div>
            <div @click="tdjm(2)" class="tdjs18"></div>
            <div @click="tdjm(0)" class="tdjs19"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import { online } from "../api/api.js"; //获取预约数量

export default {
  data() {
    return {
      wyy:0,
      zbzt:7,//控制当前的直播状态 1=预约  2=正在直播  3=直播结束明年再来 4=直播结束明天再来
      yxrs: 0, //已经预约的人数
      xsjbx: { imd: 1, img: require("../assets/tx/1.jpg") },
      // heis:1,
      spbfs: 1, //控制视频播放的时候隐藏封面
      // jsnr: 0, //关于OPA的介绍详情
      dhycjs: 1, //大会议程的时间切换
      lis: 0, //所有图片的宽度
      ymnr: {}, //页面的整个内容
      mossz: [], //轮播图中的图片
      tpyl: [], //图片预览
      moimgs: [],
      tpyl: [
             require("../assets/wq/1d.png"),
             require("../assets/wq/1s.png"),
             require("../assets/wq/2s.png"),
             require("../assets/wq/3s.png"),
             require("../assets/wq/4s.png"),
             require("../assets/wq/2d.png"),
             require("../assets/wq/1s2.png"),
             require("../assets/wq/2s2.png"),
             require("../assets/wq/3s2.png"),
             require("../assets/wq/4s.png"),
          ],//往期回顾中轮播图的图片点击预览
      moll: [
            {
              imgd: require("../assets/wq/1d.png"),
              img1: require("../assets/wq/1s.png"),
              img2: require("../assets/wq/2s.png"),
              img3: require("../assets/wq/3s.png"),
              img4: require("../assets/wq/4s.png"),
            },
            {
              imgd: require("../assets/wq/2d.png"),
              img1: require("../assets/wq/1s2.png"),
              img2: require("../assets/wq/2s2.png"),
              img3: require("../assets/wq/3s2.png"),
              img4: require("../assets/wq/4s.png"),
            },
          ],//往期回顾中轮播图
      jbjs: [
        {
          imd: 1,
          img: require("../assets/jb/1.png"),
          imgx: require("../assets/jb/1x.png"),
          imgz: require("../assets/jb/1z.png"),
        },
                        {
          imd: 2,
          img: require("../assets/jb/4.png"),
          imgx: require("../assets/jb/4x.png"),
          imgz: require("../assets/jb/4z.png"),
        },
        {
          imd: 3,
          img: require("../assets/jb/2.png"),
          imgx: require("../assets/jb/2x.png"),
          imgz: require("../assets/jb/2z.png"),
        },
        {
          imd: 4,
          img: require("../assets/jb/3.png"),
          imgx: require("../assets/jb/3x.png"),
          imgz: require("../assets/jb/3z.png"),
        },
        {
          imd: 5,
          img: require("../assets/jb/5.png"),
          imgx: require("../assets/jb/5x.png"),
          imgz: require("../assets/jb/5z.png"),
        },
        {
          imd: 6,
          img: require("../assets/jb/6.png"),
          imgx: require("../assets/jb/6x.png"),
          imgz: require("../assets/jb/6z.png"),
        },
      ], //嘉宾介绍的图片的图片


 
      zzcy1: [],
      zzcy2: [],
      jbjss:0,
    };
  },
  created(){
                  window.localStorage.removeItem("phones");
                window.localStorage.removeItem("names");
                this.zbzt=7
  },
  mounted() {
   this.zbzt=7
    let totoken = window.localStorage.getItem("phones");
    let wang = window.localStorage.getItem("names");
    if ((totoken == null && wang == null) || totoken == "") {
      this.wyy=0
    } else {
      this.wyy=1
    }

        this.lis=0,
    clearInterval(this.timer);
    this.pbl();
    this.ditu();
    this.ditu2();
    // this.spjs();
    // this.zzcytpfl();
    online({}).then((red) => {
      console.log(red);
      this.yxrs = red.data.count;
    });

                      setTimeout(() => {
                    this.zbzt=7
                  }, 500);
  },
  methods: {
        // 回放链接
    hf19(){
      window.location.href="https://www.zhi-niao.com/znlive/pages/?code=071dONFa1UaikC0448Ia1ykl2w3dONFH&state=#/shareLive?appid=com.pingan.zhiniao&source=liveShow&h5ShareType=2&liveRoomId=5235621&shareUserId=ZHANGXIAOCHENG834&shareEnterpriseId=0EA3756D964D21C3E054A0369F1934EC&sourceUserId=74C4C3508BF146FBB9CD87514D807D2B&liveKind=2&dataSource=10030005&shareScene=0&roomId=5235621&title=2021OPA%20%E5%B9%B3%E5%AE%89%E9%9B%86%E5%9B%A2%E4%BD%93%E9%AA%8C%E8%AE%BE%E8%AE%A1%E5%A4%A7%E4%BC%9A&imgUrl=https%3A%2F%2Fmlearn.pingan.com.cn%2Flearn%2Fapp%2Ffilehub%2F0001%2Fformal%2Fimage%2F2021%2F12%2F14%2F382674354F9B4006BEDE2E158476B728%2F439a1fd79beb479096629b7d24866444.png&intro=2021OPA%20%E5%B9%B3%E5%AE%89%E9%9B%86%E5%9B%A2%E4%BD%93%E9%AA%8C%E8%AE%BE%E8%AE%A1%E5%A4%A7%E4%BC%9A"
    },
  hf20(){
      window.location.href="https://www.zhi-niao.com/znlive/pages/?code=061SGj100Wzq0N1IKf400PB7bb2SGj1-&state=#/shareLive?appid=com.pingan.zhiniao&source=liveShow&h5ShareType=2&liveRoomId=5235623&shareUserId=ZHANGXIAOCHENG834&shareEnterpriseId=0EA3756D964D21C3E054A0369F1934EC&sourceUserId=74C4C3508BF146FBB9CD87514D807D2B&liveKind=2&dataSource=10030005&shareScene=0&roomId=5235623&title=2021OPA%20%E5%B9%B3%E5%AE%89%E9%9B%86%E5%9B%A2%E4%BD%93%E9%AA%8C%E8%AE%BE%E8%AE%A1%E5%A4%A7%E4%BC%9A&imgUrl=https%3A%2F%2Fmlearn.pingan.com.cn%2Flearn%2Fapp%2Ffilehub%2F0001%2Fformal%2Fimage%2F2021%2F12%2F14%2F3B2D1C80C3EF4FCDACA37895177AA136%2F08dd54e4e685408cb65aa97e168b6f7a.png&intro=2021OPA%20%E5%B9%B3%E5%AE%89%E9%9B%86%E5%9B%A2%E4%BD%93%E9%AA%8C%E8%AE%BE%E8%AE%A1%E5%A4%A7%E4%BC%9A"
    },
    // 回放链接
    zzzsb(){
      // window.location.href="https://www.zhi-niao.com/znlive/pages/#/shareLive?appid=com.pingan.zhiniao&source=liveShow&h5ShareType=2&liveRoomId=5235623&shareUserId=LIMIN370&shareEnterpriseId=0EA3756D964D21C3E054A0369F1934EC&sourceUserId=LIMIN370&liveKind=2&dataSource=10030005&shareScene=0&roomId=5235623&title=2021OPA%20%E5%B9%B3%E5%AE%89%E9%9B%86%E5%9B%A2%E4%BD%93%E9%AA%8C%E8%AE%BE%E8%AE%A1%E5%A4%A7%E4%BC%9A&imgUrl=https%3A%2F%2Fmlearn.pingan.com.cn%2Flearn%2Fapp%2Ffilehub%2F0001%2Fformal%2Fimage%2F2021%2F12%2F14%2F3B2D1C80C3EF4FCDACA37895177AA136%2F08dd54e4e685408cb65aa97e168b6f7a.png&intro=%E7%9F%A5%E9%B8%9F%E7%9B%B4%E6%92%AD-%E5%BC%A0%E5%BC%9B,%E4%BA%8E12%E6%9C%8820%E6%97%A509%3A00..."
    },
    //直播链接跳转其他网站
    zzzb(){
      window.location.href="https://www.zhi-niao.com/znlive/pages/#/shareLive?appid=com.pingan.zhiniao&source=liveShow&h5ShareType=2&liveRoomId=5235623&shareUserId=LIMIN370&shareEnterpriseId=0EA3756D964D21C3E054A0369F1934EC&sourceUserId=LIMIN370&liveKind=2&dataSource=10030005&shareScene=0&roomId=5235623&title=2021OPA%20%E5%B9%B3%E5%AE%89%E9%9B%86%E5%9B%A2%E4%BD%93%E9%AA%8C%E8%AE%BE%E8%AE%A1%E5%A4%A7%E4%BC%9A&imgUrl=https%3A%2F%2Fmlearn.pingan.com.cn%2Flearn%2Fapp%2Ffilehub%2F0001%2Fformal%2Fimage%2F2021%2F12%2F14%2F3B2D1C80C3EF4FCDACA37895177AA136%2F08dd54e4e685408cb65aa97e168b6f7a.png&intro=%E7%9F%A5%E9%B8%9F%E7%9B%B4%E6%92%AD-%E5%BC%A0%E5%BC%9B,%E4%BA%8E12%E6%9C%8820%E6%97%A509%3A00..."
    },
    //跳转团队界面
    tdjm(val){
      clearInterval(this.timer);
      console.log(val)
this.$router.push({
 path:"/team/"+val
      });
    },
    //跳转相应的供货商
    ghstz(val){
      clearInterval(this.timer);
      console.log(val)
this.$router.push({
 path:"/supplier/"+val
      });
    },
    //大会议程模块索引
    tz19(val){
            clearInterval(this.timer2);
      clearInterval(this.timer);
      this.$router.push({
   path:"/guest/"+val
      });
    },
    //嘉宾介绍索引
    onChange(index) {
      console.log(index);
      this.jbjss=index
      // Toast('当前 Swipe 索引：' + index);
    },
     //嘉宾介绍模块
    cktxx(val, i) {
      this.xsjbx = val;
      this.$refs.swiper.swipeTo(i);
    },
    // //跳转优质供货商页面
    // yzghsym() {
    //   clearInterval(this.timer2);
    //   clearInterval(this.timer);
    //   this.$router.push({
    //     name: "supplier",
    //     params: {
    //       na: this.xsjbx,
    //     },
    //   });
    // },
    //跳转详情嘉宾页面
    tcxqjb(val) {
      // clearInterval(this.timer2);
      clearInterval(this.timer);
      this.$router.push({
        path:"/guest/"+val
        // name: "guest",
        // params: {
        //   na: val,
        // },
      });
    },
    //跳转预约页面
    tcyy() {
      // clearInterval(this.timer2);
      clearInterval(this.timer);
      this.$router.push({
        name: "yy",
        params: {
          // na:this.zzcytp,
        },
      });
    },
    //花絮视频点击开始播放
    ksbfsp() {
      this.spbfs = 0;
      var spbf = document.getElementById("spbf");
      spbf.play();
      spbf.loop = true;
    },
    //花絮视频播放结束后显示封面
    spjs() {
      var elevideo = document.getElementById("spbf");
      var this_ = this;
      elevideo.addEventListener(
        "ended",
        function () {
          //结束
          console.log("播放结束");
          this_.spbfs = 1;
        },
        false
      );
    },
    //地图模块点击跳转导航1
    tztu1() {
      console.log(1);
      window.open(
        "https://uri.amap.com/poidetail?poiid=B02F3007DA&src=mypage&callnative=1"
        // "_blank",
        // "rel=noopener noreferrer"
      );
    },
    //地图模块点击跳转导航2
    tztu2() {
      console.log(1);
      window.open(
        "https://uri.amap.com/poidetail?poiid=B0FFLHQOFY&src=mypage&callnative=1"
        // "_blank",
        // "rel=noopener noreferrer"
      );
    },
    //地图定位
    ditu() {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: [114.107534, 22.540563],
        zoom: 12,
        zoomEnable: false,
      });
      var marker = null,
        infoWindow = null;
      addMarker();
      //添加marker标记
      function addMarker() {
        map.clearMap();
        marker = new AMap.Marker({
          map: map,
          position: [114.107534, 22.540563],
        });
        //获取经纬度
        map.on("click", function (e) {
          console.log(e.lnglat.getLng() + "," + e.lnglat.getLat());
        });
        //鼠标点击marker弹出自定义的信息窗体
        AMap.event.addListener(marker, "click", function () {
          window.open(
            "https://uri.amap.com/poidetail?poiid=B02F3007DA&src=mypage&callnative=1"
            // "_blank",
            // "rel=noopener noreferrer"
          );
        });
      }
    },
    //地图定位2
    ditu2() {
      var map = new AMap.Map("container2", {
        resizeEnable: true,
        center: [121.554103, 31.243134],
        zoom: 12,
        zoomEnable: false,
      });
      var marker = null,
        infoWindow = null;
      addMarker();
      //添加marker标记
      function addMarker() {
        map.clearMap();
        marker = new AMap.Marker({
          map: map,
          position: [121.554103, 31.243134],
        });
        //获取经纬度
        map.on("click", function (e) {
          console.log(e.lnglat.getLng() + "," + e.lnglat.getLat());
        });
        //鼠标点击marker弹出自定义的信息窗体
        AMap.event.addListener(marker, "click", function () {
          window.open(
            "https://uri.amap.com/poidetail?poiid=B0FFLHQOFY&src=mypage&callnative=1"
            // "_blank",
            // "rel=noopener noreferrer"
          );
        });
      }
    },

    // 往期回顾的轮播----------------------------------------------------------------------------------------------------------------------------------------------
       // 往期回顾的轮播----------------------------------------------------------------------------------------------------------------------------------------------
    //把拿到的数据分开放到两个数组中
    pbl() {
      this.moll = this.moll.concat(this.moll);
      console.log(this.moll);

      setTimeout(() => {
        this.jsul();
      }, 1000);
    },
    // 111往期回顾模块的点击图片放大预览
    fdtp(i) {
      console.log(i);
      var ass = 0;
      for (var a = 0; a < this.tpyl.length; a++) {
        if (this.tpyl[a] == i) {
          ass = a;
        }
      }
      console.log(ass);
      clearInterval(this.timer); //鼠标点击清除定时器
      var this_ = this;
      ImagePreview({
        images: this.tpyl,
        closeable: true,
        // showIndex:false,
        startPosition: ass,
        onClose() {
          console.log(147258);
          this_.timer = setInterval(() => {
            this_.move();
          }, 30);
        },
      });
    },
    //111往期回顾模块获取图片的长度
    jsul() {
      var oli = document.getElementsByClassName("oli");
      for (var i = 0; i < oli.length; i++) {
        this.lis += oli[i].offsetWidth;
        if (i == oli.length - 1) {
          this.tpgd();
          console.log(this.lis);
        }
      }
    },
    //111往期回顾模块把图片的长度赋值给父元素图片动起来
    tpgd() {
      console.log("循环完了", this.lis);
      var oul = document.getElementById("ul1");
      oul.style.width = this.lis + "px";
      this.timer = setInterval(() => {
        this.move();
      }, 30);
    },
    //111往期回顾模块让图片动起来
    move() {
      var oul = document.getElementById("ul1");
      if (oul.offsetLeft < -(oul.offsetWidth / 2)) {
        //向左滚动，当靠左的图4移出边框时
        oul.style.left = 0;
      }
      // if (oul.offsetLeft > 0) { //向右滚动，当靠右的图1移出边框时
      //     oul.style.left = -(oul.offsetWidth / 2) + 'px';
      // }
      oul.style.left = oul.offsetLeft + -1 + "px"; //向左滚动的时候为-2，向右滚动为正2
    },
    // ----------------------------------------------------------------------------------------------------------------------------------------------
    //大会议程模块的时间切换
    dyyysj1() {
      this.dhycjs = 1;
    },
    //大会议程模块的时间切换
    dyyysj2() {
      this.dhycjs = 2;
    },
    // //关于OPA模块的介绍详情内容
    // jsxq() {
    //   this.jsnr = 1;
    // },
  },
};
</script>
<style scoped>
.possk{
  width: 4.5rem;
  height: 0.7rem;
  /* background-color: #24b4f1; */
  position: absolute;
  left: 50%;
  top: 2.8rem;
  transform: translate(-50%);
}
.sbtn1{
  left: 0;
}
.sbtn2{
  right: 0;
}

.sbtnn{
  top:0rem;
  position: absolute;
  /* left: 50%; */
  /* transform: translate(-50%); */
  /* width: 2.08rem; */
  height: 0.5rem;
}
.zbjs{
  width: 4.78rem;
  position: absolute;
  left: 50%;
  top: 1rem;
  /* top: 50%; */
  transform: translate(-50%);
}
.chzxts .tdjs1{
  top: 1.25rem;
    width: 0.7rem;
    height: 0.55rem;
  left: 0rem;
}
.chzxts .tdjs2{
  top: 1.25rem;
  left: 1.48rem;
    width: 0.8rem;
    height: 0.55rem;

}
.chzxts .tdjs3{
  top: 1.25rem;
  left: 2.95rem;
    width: 0.8rem;
    height: 0.55rem;

}
.chzxts .tdjs4{
  top: 1.25rem;
  left: 4.445rem;
    width: 0.8rem;
    height: 0.55rem;

}
.chzxts .tdjs5{
  top: 1.25rem;
  left: 6.1rem;
    width: 0.8rem;
    height: 0.55rem;

}
.chzxts .tdjs6{
  top: 2.3rem;
    width: 0.4rem;
    height: 0.6rem;
  left: 0rem;
}
.chzxts .tdjs7{
  left: 1.48rem;
  top: 2.3rem;
    width: 0.6rem;
    height: 0.6rem;
}
.chzxts .tdjs8{

  left: 3.2rem;
  top: 2.3rem;
    width: 0.4rem;
    height: 0.55rem;
}
.chzxts .tdjs9{
  left: 4.75rem;
  top: 2.3rem;
    width: 0.4rem;
    height: 0.6rem;
}
.chzxts .tdjs10{
  left: 6.3rem;
  top: 2.3rem;
    width: 0.55rem;
    height: 0.6rem;
}
.chzxts .tdjs11{
  top: 3.3rem;
  left: 0rem;
    width: 0.95rem;
    height: 0.4rem;
}
.chzxts .tdjs12{
  left: 1.6rem;
  top: 3.3rem;

    width: 0.95rem;
    height: 0.5rem;
}
.chzxts .tdjs13{
  /* top: 4.15rem; */
  left: 3.12rem;
  top: 3.3rem;
  /* left: 0rem; */
    width: 1.1rem;
    height: 0.4rem;
}
.chzxts .tdjs14{
  /* top: 4.15rem; */
  left: 5rem;
  top: 3.3rem;
  /* left: 0rem; */
    width: 1.8rem;
    height: 0.4rem;
}
.chzxts .tdjs15{
  top: 4.1rem;
  left: 0rem;
    width: 1.32rem;
    height: 0.6rem;
}
.chzxts .tdjs16{
  /* top: 5.5rem; */
  left: 1.6rem;
    width: 1.32rem;
    height: 0.6rem;
      top: 4.1rem;
}
.chzxts .tdjs17{
  /* top: 5.5rem; */
  left:3.25rem;
    width: 0.8rem;
    height: 0.6rem;
      top: 4.1rem;
}
.chzxts .tdjs18{
  top: 4.1rem;
  left: 4.4rem;
    width: 0.85rem;
    height: 0.6rem;
}
.chzxts .tdjs19{
  top: 4.1rem;
  left: 5.7rem;
    width: 1.1rem;
    height: 0.6rem;
}
.chzxts div{
  position: absolute;
  /* border: 1px solid yellow; */
}
.chzxts{
    width: 6.9rem;
  height: 6.66rem;
  /* background-color: rgba(88, 88, 88,0.4); */
  position: absolute;
}
.chzxt {
  position: absolute;
  left: 0px;
  width: 6.9rem;
  height: 6.66rem;
}
.chzxter {
  position: absolute;
  left: 5.7rem;
  width: 1.2rem;
  top: 5.2rem;
  z-index: 9;
  /* height: 6.66rem; */
}
.jbjstb {
  width: 4rem;
  position: absolute;
  bottom: -1.1rem;
  right: -1rem;
  z-index: -1;
}
.chzx {
  position: relative;
  width: 6.9rem;
  height: 7rem;
  /* background-color: #24b4f1; */
  left: 50%;
  transform: translate(-50%);
}
.xbxqt img {
  /* width: 1.1rem; */
  height: 2rem;
}
.xbxqt {
  white-space: nowrap;

  overflow-x: auto;
  overflow-y: hidden;
  width: 6.9rem;
  height: 2rem;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  /* background-color: rgb(122, 117, 116); */
}
.xqyms img {
  height: 100%;
}
.xqyms {
  width: 6.9rem;
  height: 2.4rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
  /* background-color: salmon; */
}
.titks{
  width: 6.9rem;
  position: absolute;
    left: 50%;
  transform: translate(-50%);
}
.jbjck {
  width: 6.9rem;
  height: 4.4rem;
  position: absolute;
  top: 1rem;
  /* background-color: rgb(35, 211, 144); */
  left: 50%;
  transform: translate(-50%);
}
@keyframes gs1 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(5rem);
  }
}
@keyframes gs2 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(4rem);
  }
}
@keyframes gs3 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(4rem);
  }
}
@keyframes gs4 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(5rem);
  }
}
@keyframes gs5 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(4rem);
  }
}
@keyframes gs6 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(5rem);
  }
}
@keyframes gs7 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(6rem);
  }
}
#gdz0 {
  animation-name: gs1;
  animation-delay: 3s;
  /* animation:none; */
}
#gdz1 {
  /* animation:gs2 2s linear 0.5s forwards; */
  animation-name: gs2;
  animation-delay: 3.5s;
}
#gdz2 {
  /* animation:gs3 2s linear 1s forwards; */
  animation-name: gs3;
  animation-delay: 4s;
}
#gdz3 {
  /* animation:gs4 2s linear 1.5s forwards; */
  animation-name: gs4;
  animation-delay: 4.5s;
}
#gdz4 {
  /* animation:gs5 2s linear 2s forwards; */
  animation-name: gs5;
  animation-delay: 5s;
}
#gdz5 {
  /* animation:gs6 2s linear 2.5s forwards; */
  animation-name: gs6;
  animation-delay: 5.5s;
}
#gdz6 {
  /* animation:gs7 2s linear 3s forwards; */
  animation-name: gs7;
  animation-delay: 6s;
}
@keyframes gs21 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(-4rem);
  }
}
@keyframes gs22 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(-3rem);
  }
}
@keyframes gs23 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(-4rem);
  }
}
@keyframes gs24 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(-3rem);
  }
}
@keyframes gs25 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(-5rem);
  }
}
@keyframes gs26 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(-4rem);
  }
}
@keyframes gs27 {
  0% {
    transform: translate(0rem);
  }
  100% {
    transform: translate(-5rem, -5rem);
  }
}
#gdz20 {
  animation-name: gs21;
  animation-delay: 3s;
  /* animation:none; */
}
#gdz21 {
  /* animation:gs2 2s linear 0.5s forwards; */
  animation-name: gs22;
  animation-delay: 3.5s;
}
#gdz22 {
  /* animation:gs3 2s linear 1s forwards; */
  animation-name: gs23;
  animation-delay: 4s;
}
#gdz23 {
  /* animation:gs4 2s linear 1.5s forwards; */
  animation-name: gs24;
  animation-delay: 4.5s;
}
#gdz24 {
  /* animation:gs5 2s linear 2s forwards; */
  animation-name: gs25;
  animation-delay: 5s;
}
#gdz25 {
  /* animation:gs6 2s linear 2.5s forwards; */
  animation-name: gs26;
  animation-delay: 5.5s;
}
#gdz26 {
  /* animation:gs7 2s linear 3s forwards; */
  animation-name: gs27;
  animation-delay: 6s;
}
.syryk .zuo img {
  transition: 1s;
  display: block;
  position: relative;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  height: 1.06rem;
}
.syryk .zuo {
  width: 2rem;
  height: 100%;
  position: absolute;
  /* overflow: hidden; */
  left: -3rem;
  background-color: #24b4f1;
}
.syryk .you img {
  transition: 1s;
  display: block;
  position: relative;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  height: 1.06rem;
}

.syryk .you {
  /* overflow: hidden; */
  width: 2rem;
  height: 100%;
  position: absolute;
  right: -3rem;
  /* background-color: #24b4f1; */
}
.syryk {
  overflow: hidden;
  position: absolute;
  width: 6.22rem;
  height: 7.47rem;
  top: 1.27rem;
  /* background-color: salmon; */
  left: 50%;
  transform: translate(-50%);
}
.gzh1 {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  top: 6.46rem;
  left: 1.1rem;
}
.gzh2 {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  top: 6.46rem;
  left: 3.95rem;
}
.gzhs {
  width: 4.04rem;
  height: 0.3rem;
  position: absolute;
  top: 8.5rem;
  left: 1.49rem;
}
.dhdds {
  width: 3.5rem;
  height: 1.4rem;
  position: absolute;
  top: 1.14rem;
  left: 0;
  /* background-color: rgba(1, 1, 1, 0.4); */
}
.bfkb {
  width: 1.2rem;
  top: 50%;
  position: absolute;
  height: 1.2rem;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bffm {
  width: 7rem;
  top: 0rem;
  position: absolute;
  height: 4.2rem;
  left: 0rem;
}
.smcs {
  width: 6.9rem;
  height: 4rem;
  position: absolute;
  top: 1.15rem;
  background-color: rgba(1, 1, 1, 0.4);
}
.spbf {
  width: 6.9rem;
  height: 4rem;
  position: absolute;
  top: 1.15rem;
}
.ksdh2 {
  position: absolute;
  width: 0.82rem;
  height: 0.82rem;
  top: 3.2rem;
  left: 4.7rem;
}
.ksdh {
  position: absolute;
  width: 0.82rem;
  height: 0.82rem;
  top: 3.2rem;
  left: 1.5rem;
}
.ditu2 {
  position: absolute;
  top: 2.7rem;
  width: 2.78rem;
  height: 1.44rem;
  left: 3.69rem;
  border-radius: 5px;
  background-color: wheat;
}
.ditu {
  position: absolute;
  top: 2.7rem;
  width: 2.78rem;
  height: 1.44rem;
  left: 0.47rem;
  border-radius: 5px;
  background-color: wheat;
}
.dhdd1{
  width: 2.2rem;
  position: absolute;
  top: 4.4rem;
  left: 0.45rem;
}
.dhdd2{
  width: 2.2rem;
  position: absolute;
  top: 4.4rem;
  left: 3.7rem;
}
.dhddtit {
  height: 0.6rem;
  /* width: 3.18rem; */
  position: absolute;
  top: 0rem;
  left: 0rem;
}
.dhdd {
  /* background-color: wheat; */
  width: 6.9rem;
  height: 6.4rem;
  overflow: hidden;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.hxsptit {
  height: 0.6rem;
  /* width: 2.93rem; */
  position: absolute;
  top: 0rem;
  left: 0rem;
}
.hxsp {
  /* background-color: wheat; */
  width: 6.9rem;
  height: 5.77rem;
  overflow: hidden;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.gwhgtit {
  height: 0.6rem;
  /* width: 2.02rem; */
  position: absolute;
  top: 0rem;
  left: 0rem;
}
/* -------------------------------- */
.ul1 li .t2 .s1 {
  height: 1.45rem;
  left: 0.05rem;
  position: absolute;
  top: 0rem;
  width: 2.68rem;
  height: 1.41rem;
}
.ul1 li .t2 .s2 {
  height: 1.45rem;
  bottom: 0;
  left: 0.05rem;
  position: absolute;
  width: 2.68rem;
  height: 1.41rem;
}
.ul1 li .t2 {
  /* background-color: rgb(106, 190, 132); */
  position: relative;
  height: 2.9rem;
  width: 2.75rem;
  display: inline-block;
}
.ul1 li .t1 {
  width: 3.7rem;
  height: 2.9rem;
  display: inline-block;
}
.uli li .t2 {
  display: inline-block;
}
.ul1 li {
  /* display: flex; */

  text-align: left;
  display: inline-block;
  /* position: relative; */
  white-space: nowrap;
  height: 2.9rem;
  width: 9.28rem;
  /* background-color: rgb(105, 87, 87); */
}
.ul1 {
  width: 100%;
  height: 100%;
  /* overflow-x: auto; */
  /* overflow-X: hidden; */
  position: absolute;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.xhgd {
  overflow-x: auto;
  overflow-Y: hidden;
  /* overflow: hidden; */
  width: 6.9rem;
  height: 2.9rem;
  /* background-color: rgb(65, 66, 66); */
  position: absolute;
  left: 50%;
  top: 0rem;
  transform: translate(-50%);
}
.xhgds {
  /* overflow-x: auto; */
  /* overflow-Y: hidden; */
  overflow: hidden;
  width: 6.9rem;
  height: 1.47rem;
  /* background-color: aqua; */
  position: absolute;
  left: 50%;
  top: 1.47rem;
  transform: translate(-50%);
}
.lbrc {
  width: 7rem;
  top: -0.3rem;
  position: absolute;
}
/* -------------------------------- */

.gwhgk {
  width: 6.9rem;
  height: 2.94rem;
  position: absolute;
  top: 1.26rem;
  /* background-color: wheat; */
}
.gwhg {
  /* background-color: wheat; */
  width: 6.9rem;
  height: 4.5rem;
  overflow: hidden;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.zzcytp {
  left: 0rem;
  /* position: absolute; */
  top: 0rem;
  width: 6.9rem;
  /* height: 9.12rem; */
}
.zzcy {
  /* background-color: wheat; */
  width: 6.9rem;
  /* height: 9.45rem; */
  overflow: hidden;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.yzghstt{
  /* width: 2.1rem; */
  height: 0.6rem;
  position: absolute;
  left: 0rem;
}
.yzghstp {
  left: 0rem;
  position: absolute;
  top: 1rem;
  width: 6.9rem;
  height: 4.79rem;
  /* background-color: red; */
}
.yzghstp .d1c{
  top: 0rem;
  left: 0rem;
  width: 2.55rem;
  height: 2.3rem;
  /* border:1px solid red; */
}
.yzghstp .d2c{
  top: 2.45rem;
  left: 0rem;
  width: 2.55rem;
  height: 2.3rem;
  /* border:1px solid red; */
}
.yzghstp .d3c{
  top: 0rem;
  right: 0rem;
  width: 4.15rem;
  height: 1.48rem;
  /* border:1px solid red; */
}
.yzghstp .d4c{
  top: 1.62rem;
  right: 0rem;
  width: 4.15rem;
  height: 1.48rem;
  /* border:1px solid red; */
}
.yzghstp .d5c{
  top: 3.27rem;
  right: 0rem;
  width: 4.15rem;
  height: 1.48rem;
  /* border:1px solid red; */
}
.yzghstp div{
  position: absolute;
}
.yzghs {
  /* background-color: wheat; */
  width: 6.9rem;
  height: 6.5rem;
  overflow: hidden;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.dhyyt1 {
  position: absolute;
  left: 0rem;
  top: 1.88rem;
  width: 6.9rem;
  height: 10.52rem;
  /* background-color: #24b4f1; */
  /* z-index: 1000; */
}
.zjjs19{
  position: absolute;
  left: 0rem;
  top: 1.88rem;
  width: 6.9rem;
  height: 10.52rem;
}
.zjjs19 div{
  width: 4.5rem;
  position: absolute;
  left: 2.4rem;
  height: 1rem;
  /* background-color: rgba(250, 250, 250, 0.1); */
  /* border: 0.01rem solid rebeccapurple; */
}
.zjjs19 .ds1{
  top: 0rem;
}
.zjjs19 .ds2{
  top: 1rem;
}
.zjjs19 .ds3{
  top: 2rem;
}
.zjjs19 .ds4{
  top: 3rem;
  height: 1.4rem;
}
.zjjs19 .ds5{
  top: 4.5rem;
}
.zjjs19 .ds6{
  top: 5.5rem;
  height: 1.3rem;
}
.zjjs19 .ds7{
  top: 6.8rem;
  height: 1.3rem;

}
.zjjs19 .ds8{
  top: 8.1rem;
  /* height: 1.3rem; */

}
.zjjs19 .ds9{
  top: 9.1rem;
  height: 1.4rem;

}
.zjjs20{
  position: absolute;
  left: 0rem;
  top: 1.88rem;
  width: 6.9rem;
  height: 10.52rem;
}
.zjjs20 div{
  width: 4.5rem;
  position: absolute;
  left: 2.4rem;
  height: 1rem;
  /* background-color: rgba(250, 250, 250, 0.1); */
  /* border: 0.01rem solid rebeccapurple; */
}
.zjjs20 .ds1{
  top: 0rem;
}
.zjjs20 .ds2{
  top: 1rem;
}
.zjjs20 .ds3{
  top: 2rem;
}
.zjjs20 .ds4{
  top: 3rem;
}
.zjjs20 .ds5{
  top: 4rem;
}
.zjjs20 .ds6{
  top: 5.15rem;
}
.zjjs20 .ds7{
  top: 6.15rem;
  height: 1.3rem;
}
.zjjs20 .ds8{
  top: 7.45rem;
}
.zjjs20 .ds9{
  top: 8.45rem;
}
.dhyyt2 {
  position: absolute;
  left: 0rem;
  top: 1.88rem;
  width: 6.9rem;
  height: 9.50rem;
}
.dhyyxs {
  left: 0rem;
  position: absolute;
  top: 1.465rem;
  width: 0.8rem;
  height: 0.05rem;
}
.dhyyxs2 {
  left: 1.1rem;
  position: absolute;
  top: 1.465rem;
  width: 0.8rem;
  height: 0.05rem;
}
#dhyysj2 {
  position: absolute;
  top: 1.08rem;
  left: 1.1rem;
  font-size: 0.3rem;
  color: #88849a;
}
#dhyysj1 {
  position: absolute;
  top: 1.08rem;
  left: 0rem;
  font-size: 0.3rem;
  color: #88849a;
}
.dhyysj {
  color: white !important;
}
.dhyyx {
  left: 0rem;
  position: absolute;
  top: 1.47rem;
  height: 0.04rem;
  width: 6.9rem;
}
.dhyytit {
  height: 0.68rem;
  width: 2.49rem;
  position: absolute;
  top: 0rem;
  left: 0rem;
}
.dhyy {
  width: 7rem;
  height: 13rem;
  position: relative;
  /* width: 100%; */
  /* background-color: aqua; */
  left: 50%;

  transform: translate(-50%);
}
.jbjstit {
  height: 0.6rem;
  /* width: 2.49rem; */
  position: absolute;
  top: 0rem;
  left: 0rem;
}
.jbjs {
  width: 100%;
  overflow: hidden;
  height: 6.5rem;
  position: relative;
  /* width: 100%; */
  /* background-color: aqua; */
  /* left: 50%; */

  /* transform: translate(-50%); */
}
.zlld {
  height: 0.6rem;
  /* width: 7rem; */
  position: absolute;
  top: 0.84rem;
  left: 0;

  /* transform: translate(-50%); */
}
.zllds {
  /* height: 0.6rem; */
  width: 7rem;
  position: absolute;
  top: 2rem;
  left: 50%;

  transform: translate(-50%);
}
.zlldk {
  height: 6.31rem;
  position: relative;
  width: 7rem;
  left: 50%;
  transform: translate(-50%);
  /* background-color: aqua; */
}
.touming {
  opacity: 0;
}
.jsxqnr {
  background-color: #130d43;
  position: relative;
  width: 100%;
  top: -0.13rem;
}
.opags2 {
  width: 7rem;
}
.opazk {
  position: absolute;
  right: -0.09rem;
  /* font-size: 0.22rem; */
  bottom: 0.09rem;
  /* color: #24b4f1; */
  width: 1.68rem;
  height: 0.24rem;
}
.gyopa .opags {
  position: absolute;
  left: 0;
  top: 0rem;
  /* transform: translate(-50%); */
  /* width: 1.79rem; */
  height: 0.6rem;
}
.gyopa .opags1 {
  position: absolute;
  font-size: 0.25rem;
  color: #CDCFE8;
  line-height: 0.4rem;
  text-align: justify;
  letter-spacing: 0.02rem;

  left: 0;
  top: 0.85rem;
  /* transform: translate(-50%); */
  width: 6.9rem;
  /* height: 2.57rem; */
}
.gyopa {
  position: relative;
  width: 6.9rem;
  height: 3.9rem;
  left: 50%;
  /* background-color: salmon; */
  transform: translate(-50%);
}
.yurs {
  font-size: 0.25rem;
  color: #b4b7d1;
  position: absolute;
  left: 50%;
  top: 3.02rem;
  transform: translate(-50%);
}
.yubtn {
  position: absolute;
  left: 50%;
  top: 2.25rem;
  transform: translate(-50%);
  width: 2.08rem;
  height: 0.6rem;
}
.yubtns {
  position: absolute;
  left: 50%;
  top: 2.25rem;
  transform: translate(-50%);
  width: 1.5rem;
  /* height: 0.6rem; */
}
.yubtnc {
  position: absolute;
  left: 50%;
  top: 2.25rem;
  transform: translate(-50%);
  width: 2.08rem;
  /* height: 0.6rem; */
}
.yuwan {
  position: absolute;
  left: 50%;
  top: 0.6rem;
  transform: translate(-50%);
  width: 4.82rem;
  height: 1.16rem;
}
.dhk {
  position: absolute;
  left: 50%;
  top: 0rem;
  transform: translate(-50%);
  width: 6.9rem;
  height: 3.9rem;
}
.dhkk {
  width: 100vw;
  height: 4.5rem;
  position: relative;
  /* background-color: rgb(117, 117, 117); */
}
.nrqy .tibb .btit {
  width: 1.87rem;
  height: 0.22rem;
  position: absolute;
  top: 0.32rem;
  right: 0.31rem;
}
.nrqy .tibb .logo {
  width: 2.23rem;
  height: 0.4rem;
  position: absolute;
  top: 0.32rem;
  left: 0.31rem;
}
.tibb {
  width: 100%;
  height: 1.28rem;
  position: relative;
  /* background-color: aqua; */
}
.nrqy {
  position: absolute;
  top: 0rem;
}
.bg .bgm {
  width: 100vw;
  position: relative;
  top: -1.65rem;
  left: 0;
  display: block;
  font-size: 0%;
}
.bg .bgms {
  width: 100vw;
  position: relative;
  top: -0.4rem;
  left: 0;
  display: block;
  font-size: 0%;
}
.bg {
  position: absolute;
  width: 100%;
  height: 100vh;
    
  /* height: 100vh; */
}
</style>