<template>
  <div>
    <div class="yhxy" v-if="yhxy == 1">
      <img src="../assets/pa/yhxy.png" alt="" class="bgss" />
      <div class="yhxyw">
        <img src="../assets/pa/yhxywb.png" alt="" class="tpwwc" /><br />
        <img
          src="../assets/pa/yyxys.png"
          alt=""
          class="btntyxy"
          @click="tyxx"
        />
      </div>
      <!-- <div class="ydxy"></div> -->
    </div>
    <div class="bg" v-if="yhxy == 0">
      <!-- 提示框 -->
      <transition name="van-fade">
        <div class="dzcg" v-if="tsk == 1">
          <span>{{ tsnr }}</span>
        </div>
      </transition>
      <div class="tibb">
        <img src="../assets/pa/logo.png" alt="" class="logo" />
        <img src="../assets/pa/btit.png" alt="" class="btit" />
      </div>
      <div class="ybtc">
        <img src="../assets/pa/yybt.png" alt="" />
      </div>
      <div class="yyink">
        <img src="../assets/pa/yyin.png" alt="" class="yyin" />
        <input
          type="text"
          class="yyin1"
          v-model="nam"
          placeholder="请输入您的真实姓名"
          id="namm"
        />
        <transition name="van-fade">
          <div class="namk" v-if="namts == 1">{{ namks }}</div>
        </transition>
        <input
          type="text"
          class="yyin2"
          v-model="phone"
          placeholder="请输入您的手机号码"
        />
        <transition name="van-fade">
          <div class="phonek" v-if="sjts == 1">{{ phoneks }}</div>
        </transition>
      </div>
      <div class="yybtnk">
        <img
          src="../assets/pa/yybtn.png"
          alt=""
          class="yybtn"
          @click="yybtn"
          v-if="tyxy == 1"
        />
        <img
          src="../assets/pa/yybtns.png"
          alt=""
          class="yybtn"
          v-if="tyxy == 0"
          @click="yybtnb"
        />
        <div :class="{ yyxzk: tyxy == 1 }" id="yyxzk" @click="yyxy">
          <img src="../assets/pa/yydh.png" alt="" class="yydh" />
        </div>
        <img src="../assets/pa/yyww.png" alt="" class="yyww" />
        <div class="yytc" @click="tzxy"></div>
        <span class="yurs">已有{{ yxrs }}人成功预约</span>
      </div>
      <img src="../assets/pa/yydb.png" alt="" class="yydb" />
    </div>
  </div>
</template>
<script>
import { userCreate } from "../api/api.js"; //预约信息上传
import { online } from "../api/api.js"; //获取预约数量
export default {
  data() {
    return {
      namts: 1,
      sjts: 1,
      tsnr: "",
      tsk: 0,
      yxrs: 0,
      yhxy: 0,
      namks: "",
      phoneks: "",
      tyxy: 0,
      nam: "",
      phone: "",
    };
  },
  mounted() {
    document.getElementById(`namm`).focus();
    // console.log(this.$route.params.na)

    online({}).then((red) => {
      console.log(red);
      this.yxrs = red.data.count;
      //  if(red.message=="添加成功"){
      //      console.log("123")
      //  }
    });
  },
  methods: {
    yybtnb() {
      this.tsk = 1;
      (this.tsnr = "请您阅读,或同意隐私协议后再提交"),
        setTimeout(() => {
          (this.tsnr = ""), (this.tsk = 0);
        }, 1000);
    },
    tyxx() {
      this.yhxy = 0;
      this.tyxy = 1;
    },
    tzxy() {
      this.yhxy = 1;
    },
    yybtn() {
      this.namks = "";
      this.phoneks = "";
      this.namts = 1;
      this.sjts = 1;
      if (
        this.nam == "" ||
        this.phone == "" ||
        !/[\W]|^[\u4E00-\u9FA5]/g.test(this.nam) ||
        !/^1[345789]\d{9}$/.test(this.phone)
      ) {
        if (this.nam == "") {
          console.log("姓名不能为空");
          this.namks = "姓名不能为空";
          setTimeout(() => {
            (this.namks = ""), (this.namts = 0);
          }, 1000);
        } else if (this.phone == "") {
          console.log("手机号不能为空");
          this.phoneks = "手机号不能为空";
          setTimeout(() => {
            (this.phoneks = ""), (this.sjts = 0);
          }, 1000);
        } else if (!/[\W]|^[\u4E00-\u9FA5]/g.test(this.nam)) {
          console.log("姓名格式错误");
          this.namks = "姓名格式错误";
          setTimeout(() => {
            (this.namks = ""), (this.namts = 0);
          }, 1000);
        } else if (!/^1[345789]\d{9}$/.test(this.phone)) {
          console.log("手机号格式错误");
          this.phoneks = "手机号格式错误";
          setTimeout(() => {
            (this.phoneks = ""), (this.sjts = 0);
          }, 1000);
        }
      } else {
        if (this.tyxy != 1) {
          console.log(0);
          var gz = document.getElementById("yyxzk");

          gz.style.backgroundColor = "#FF0000";
        } else if (
          this.tyxy == 1 &&
          /[\W]|^[\u4E00-\u9FA5]/g.test(this.nam) &&
          /^1[345789]\d{9}$/.test(this.phone)
        ) {
          console.log("OK");
          this.namks = "";
          this.phoneks = "";
          this.tsk = 1;
          this.tsnr = "正在预约中";
          userCreate({ name: this.nam, phone: this.phone, type: 1 }).then(
            (red) => {
              console.log(red);
              if (red.message == "添加成功") {
                console.log("123");
                window.localStorage.setItem("phones", this.phone);
                window.localStorage.setItem("names", this.nam);
                this.yxrs += 1;
                (this.tsnr = "您已成功预约2021OPA体验设计⼤会线上直播"),
                  setTimeout(() => {
                    (this.tsnr = ""), (this.tsk = 0);
                    this.$router.push("/");
                  }, 1000);
              } else if (red.message == "您已预约请勿重复") {
                console.log("456");
                (this.tsnr = "您已预约过请勿重复"),
                  setTimeout(() => {
                    (this.tsnr = ""), (this.tsk = 0);
                  }, 1000);
              } else {
                (this.tsnr = "网络错误,请您稍后重试"),
                  setTimeout(() => {
                    (this.tsnr = ""), (this.tsk = 0);
                  }, 1000);
              }
            }
          );
        }
      }
    },
    yyxy() {
      if (this.tyxy == 0) {
        this.tyxy = 1;
      } else if (this.tyxy == 1) {
        var gz = document.getElementById("yyxzk");
        gz.style.backgroundColor = "#8589a8";
        this.tyxy = 0;
      }
    },
  },
};
</script>
<style scoped>
.dzcg {
  position: fixed;
  width: 5rem;
  /* padding-left: 0.5rem;
  padding-right: 0.5rem; */
  /* height: 1rem; */
  border-radius: 18px;
  /* background-color: rgba(0, 0, 0, 0.7); */
  top: 30%;
  z-index: 9999;
  left: 50%;
  /* text-align: center; */
  transform: translate(-50%);
  line-height: 0.5rem;
  font-size: 0.35rem;
  color: rgb(255, 255, 255);
}
.dzcg span {
  border-radius: 10px;

  display: inline-block;
  background-color: rgba(0, 0, 0, 0.7);
  /* background-color: aqua; */
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.btntyxy {
  width: 6.7rem;
  position: relative;
  top: 1rem;
  /* text-align: center; */
}
.tpwwc {
  width: 6.9rem !important;
  position: relative;
  top: 0.5rem;
  height: 18.6rem;
  /* text-align: center; */
}
.yhxyw {
  text-align: center;
  position: absolute;
  top: 0rem;
  width: 100%;
}
/* .yhxy .ydxy{
    position: relative;

    width: 6.09rem;
    height: 0.9rem;
    left: 50%;
    border-radius: 1rem;
    transform: translate(-50%);
    background-color: rgba(248, 78, 78, 0.8);
} */
.yhxy .bgss {
  width: 100%;
  position: relative;
}
.yhxy {
  /* overflow-Y: auto; */
  /* overflow-X: hidden; */
  width: 100%;
  height: 100vh;
}
.yytc {
  width: 1.64rem;
  height: 0.22rem;
  position: absolute;
  top: 0.06rem;
  /* background-color: rgba(0, 0, 0, 0.5); */
  left: 1.1rem;
}
.yurs {
  font-size: 0.25rem;
  color: #a2a6be;
  position: absolute;
  left: 50%;
  top: 1.96rem;
  transform: translate(-50%);
}
.namk {
  position: absolute;
  top: 0.65rem;
  left: 1.7rem;
  font-size: 0.2rem;
  color: rgb(255, 111, 111);
}
.phonek {
  position: absolute;
  top: 1.89rem;
  left: 1.7rem;
  font-size: 0.2rem;
  color: rgb(255, 111, 111);;
}
.yyin1 {
  width: 3.85rem;
  height: 0.33rem;
  position: absolute;
  top: 0.28rem;
  left: 1.65rem;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(247, 241, 241);
  font-size: 0.29rem;
  /* line-height: 0.33rem; */
  border: none;
}
.yyin2 {
  width: 3.85rem;
  height: 0.33rem;
  position: absolute;
  top: 1.54rem;
  left: 1.65rem;
  background-color: rgba(0, 0, 0, 0);
  color: rgb(247, 241, 241);
  font-size: 0.29rem;
  /* line-height: 0.33rem; */
  border: none;
}
.yydh {
  width: 0.15rem;
  position: absolute;
  top: 0.08rem;
  left: 0.06rem;
}
.yyxzk {
  background-color: #3e9bf2 !important ;
}
#yyxzk {
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
  top: 0.04rem;
  left: 0.04rem;
  border-radius: 50%;
  background-color: #8589a8;
}
.yyww {
  position: absolute;
  height: 0.22rem;
  left: 0.36rem;
  top: 0.06rem;
}
.yydb {
  position: absolute;
  bottom: 0rem;
  z-index: 0;
  width: 100%;
  left: 0;
}
.yybtnk {
  width: 6.1rem;
  height: 2.28rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  z-index: 1;
  /* background-color: white; */
}
.yybtn {
  width: 6.1rem;
  height: 0.95rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0.5rem;
}
.yyin {
  width: 6.11rem;
  height: 2.2rem;
}
.yyink {
  width: 6.11rem;
  height: 2.84rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  z-index: 1;
  /* background-color: aqua; */
}
.ybtc img {
  width: 2.43rem;
  height: 3.72rem;
  position: absolute;
  left: 0rem;
}
.ybtc {
  width: 6.4rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
  height: 5.02rem;
  /* background-color: salmon; */
}
.bg {
  background: url(../assets/pa/yy.png) no-repeat;
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 13rem;
  background-size: cover;
  background-position: center top;
  overflow: hidden;
  /* z-index: -2; */
}
.tibb .btit {
  width: 1.87rem;
  height: 0.22rem;
  position: absolute;
  top: 0.32rem;
  right: 0.31rem;
}
.tibb .logo {
  width: 2.23rem;
  height: 0.4rem;
  position: absolute;
  top: 0.32rem;
  left: 0.31rem;
}
.tibb {
  width: 100%;
  height: 1.61rem;
  position: relative;
  /* background-color: aqua; */
}
</style>