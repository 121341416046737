<template>
  <div class="bg">
    <!-- 轮播图 -->
    <h4 style="height:0.2rem">轮播图</h4>
    <van-swipe
      class="my-swipe"
      :duration="500"
      :autoplay="2000"
      indicator-color="white"
      :height="200"
      @change="onChange"
    >
      <van-swipe-item class="lunbo"
        ><img src="../assets/1.jpg" alt=""
      /></van-swipe-item>
      <van-swipe-item class="lunbo"
        ><img src="../assets/2.jpg" alt=""
      /></van-swipe-item>
      <van-swipe-item class="lunbo"
        ><img src="../assets/3.jpg" alt=""
      /></van-swipe-item>
      <van-swipe-item class="lunbo"
        ><img src="../assets/4.jpg" alt=""
      /></van-swipe-item>
    </van-swipe>
    <!-- 点击切换 -->
    <h4 style="height:0.2rem">点击切换</h4>

    <div class="qieh">
      <div class="xqy"><img :src="zhutu" alt=""></div>
      <div class="xzk">
        <li v-for="(val,i) in moll" :key="i" ><img :src="val" alt="" @click="tpl(val)"></li>
      </div>
    </div>
    <!-- 图片循环滚动 -->
    <h4 style="height:0.2rem">图片循环滚动</h4>

    <div class="xhgd" id="gdk">
      <ul id="ul1" class="ul1">
        <li class="oli" v-for="(val,i) in moll" :key="i" :id='`ds${i}`'><img :src="val" @click="fdtp(i)" /></li>
      </ul>
    </div>
    <!-- 视频播放 -->
    <h4 style="height:0.2rem" class="dingwei" >视频播放</h4>

    <div class="spbf">
        <video src="../assets/let_it_go.mp4" 
        width="350" 
        height="150"
        poster="../assets/logo.jpg"
        controls
        preload
        ></video>
    </div>
    <!--  定位地图-->
    <h4 style="height:0.2rem" class="dingwei">定位地图</h4>

    <div class="ditu" id="container"></div><br><br><br>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';

export default {
  data() {
    return {
      zhutu:require("../assets/1.jpg"),
      lis:0,//所有图片的宽度
      moll:[],
      moio:[
        require("../assets/1.jpg"),
        require("../assets/2.jpg"),
        require("../assets/3.jpg"),
        require("../assets/4.jpg"),
        require("../assets/5.jpg"),
        require("../assets/6.jpg"),
        require("../assets/7.jpg"),
      ]
    };
  },
  mounted() {
    // this.moll=this.moio
    this.moll= this.moio.concat(this.moio);
    console.log(this.moll)

    this.ditu();
    setTimeout(() =>{
      this.jsul()
    },1000)
    
  },
  methods: {
    onChange(index) {
      console.log(index)
      // Toast('当前 Swipe 索引：' + index);
    },
    ditu() {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: [120.060009, 30.288048],
        zoom: 18,
        zoomEnable: false,
      });
      var marker = null,
        infoWindow = null;
      addMarker();
      //添加marker标记
      function addMarker() {
        map.clearMap();
        marker = new AMap.Marker({
          map: map,
          position: [120.060009, 30.288048],
        });
        //获取经纬度
        map.on("click", function (e) {
          console.log(e.lnglat.getLng() + "," + e.lnglat.getLat());
        });
        //鼠标点击marker弹出自定义的信息窗体
        AMap.event.addListener(marker, "click", function () {
          window.open(
            "https://surl.amap.com/enumWIx135QJ",
            "_blank",
            "rel=noopener noreferrer"
          );
        });
      }
    },
  tpl(i){
    this.zhutu=i
  },
    fdtp(i){
      clearInterval(this.timer); //鼠标点击清除定时器
      var this_=this
        ImagePreview({
            images:this.moll,
            closeable: true,
            // showIndex:false,
            startPosition:i,
                     
            onClose(){
                    console.log(147258)
                   this_.timer=setInterval(()=>{
                    this_.move()
            }, 30);
            }
          });
    },
    jsul(){
      var oli = document.getElementsByClassName("oli")
          for(var i=0;i<oli.length;i++){
              this.lis+=oli[i].offsetWidth 
              if(i==oli.length-1){
                this.tpgd()
                console.log(this.lis)
              }
            }
    },
    tpgd(){
            console.log("循环完了",this.lis)
            var oul= document.getElementById('ul1');
            oul.style.width=this.lis+"px"
            this.timer=setInterval(()=>{
                this.move()
            }, 30);
    },
    move(){
            var oul= document.getElementById('ul1');
              if (oul.offsetLeft < -(oul.offsetWidth / 2)) { //向左滚动，当靠左的图4移出边框时
                    oul.style.left = 0;
              }
              oul.style.left = oul.offsetLeft + -2 + 'px';
        }
  },
};
</script>
<style scoped>
.dingwei{
  position: relative;
  top: 1rem;
  position: relative;
}
.xzk li{
  display: inline-block;
  height: 100%;
  margin-right: 5px;
  
}
.xzk li img{
  display: inline-block;
  height: 100%;
}
.xzk{
  white-space: nowrap;

   overflow-X: auto;
   overflow-Y: hidden;
  width: 100%;
  height: 2rem;
  /* background-color: rgb(148, 148, 50); */
  position: absolute;
  bottom: 0;
}
.xqy img{
  height: 100%;
  max-width: 90%;
}
.xqy{
  width: 100%;
  height: 2.8rem;
  background-color: beige;
  position: absolute;
}
.qieh{
  width: 7rem;
  height: 5rem;
  /* background-color: aqua; */
  top: 0.5rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
}
.spbf{
  position: relative;
width: 7rem;
top: 2rem;
left: 50%;
transform: translate(-50%);
}
.ul1 li img {
  height: 100%;
}
.ul1 li {
  display: inline-block;
  position: relative;
  height: 2rem;
}
.ul1 {
  width: 100%;
  height: 100%;
  /* overflow-x: auto; */
  /* overflow-X: hidden; */
  position: absolute;
  left: 0;
  white-space: nowrap;
  /* overflow: hidden; */

}
.xhgd {
    overflow-x: auto;
  overflow-Y: hidden;
  /* overflow: hidden; */
  width: 7rem;
  height: 2rem;
  /* background-color: aqua; */
  position: relative;
  left: 50%;
  top: 1rem;
  transform: translate(-50%);
}
.lunbo {
  width: 7rem;
  /* height: 200px; */

}
.lunbo:hover{
  transform: rotate(2);
}
.lunbo img {
  width: 100%;
}
.ditu {
  width: 7rem;
  height: 6rem;
  background-color: aqua;
  position: relative;
  left: 50%;
  top: 2rem;
  transform: translate(-50%);
  
}
.bg {
  /* text-align: center; */
  /* background: url(../assets/7.jpg) no-repeat; */
  background-color: rgb(216, 216, 216);
  width: 100%;
  height: 100vh;
  min-height: 13rem;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  overflow: auto;
  /* flex: none; 不会自动缩放 */
}
</style>