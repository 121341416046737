import {get, post } from "../utils/request.js"

//  获取作品列表
export function userCreate(param) {
    return post(`/userCreate`, param)
}

// 上传图片
export function online(param) {
    return post(`/online`, param)
}